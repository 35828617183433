import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { PdfDocumentCheckbox } from './pdf-document-checkbox';
import { PdfDocumentParagraph } from './pdf-document-paragraph';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { Constants } from '@core/constants';

export class ProtocolPdfArAiService extends ProtocolPdfStandardService {
  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader() + this.getNewLine(2, 2);
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderReport');
    baseY = this.addInspectionHeader(protocolPdfDto.inspection, protocolPdfDto.protocol, baseY);
    baseY =
      this.addInspectionPersonInformation(protocolPdfDto.inspection, baseY, true) +
      this.getNewLine(1, 3);
    baseY = this.addPageBody(protocolPdfDto, baseY);
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save(this.getProtocolFileName(protocolPdfDto.inspection.inspectionId));
    this.translateService.use(originalLanguage);
  }

  protected getProtocolFileName(inspectionId: number): string {
    return `kontrolle-${inspectionId}.pdf`;
  }

  protected addPageBody(protocolPdfDto: ProtocolPdfDto, baseY: number) {
    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    const inspectionResults = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defectOrFieldValue,
    });
    baseY = this.addInspectionResults(protocolPdfDto, inspectionResults, baseY);
    baseY = this.addNkCategoryResultsDisplayedInProtocol(protocolPdfDto, baseY);
    if (this.hasDefects(protocolPdfDto)) {
      baseY += 2 * ProtocolPdfConstants.lineHeight;
      baseY = this.addDocumentationCheckboxes(baseY);
    } else {
      baseY += ProtocolPdfConstants.lineHeight;
      baseY = this.addDocumentationCheckboxes(baseY, false);
    }
    baseY = this.addCommentOfDepartment(protocolPdfDto, baseY);
    baseY = this.addStatement(protocolPdfDto.protocol, baseY);
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY, false);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(2);
    baseY = this.addFurtherProcedureDetails(baseY);
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        inspectionResults
      );
    }
    return baseY;
  }

  protected addPageHeader(): number {
    this.pdfDocument.setFont('helvetica');
    const imageData = this.getImageData(Constants.imageArAi);
    this.addImage(
      imageData,
      'png',
      ProtocolPdfConstants.marginX - 8,
      ProtocolPdfConstants.marginYFirstPage,
      ProtocolPdfConstants.maxXValue - ProtocolPdfConstants.marginX + 5,
      38
    );
    return 45;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const margin2ndColumn = ProtocolPdfConstants.marginX + 70;

    const signatureBlockHeight = 11 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }

    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.addCurrentDateWithLabel(baseY, inspection.inspectionDate);
    baseY += 2 * ProtocolPdfConstants.lineHeight;

    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureInspectionPerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureResponsiblePerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight / 2;
    const labelRejectedWidth = 20;

    const checkBox = new PdfDocumentCheckbox();
    checkBox.maxTextWidth = labelRejectedWidth;
    checkBox.currentPositionY = baseY;
    checkBox.positionX = ProtocolPdfConstants.maxXValue - labelRejectedWidth;
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelSignatureRejected');
    this.addCheckbox(checkBox);

    baseY = this.addAdditionalInformationCheckboxes(baseY);
    return baseY;
  }

  protected addCurrentDateWithLabel(
    baseY: number,
    inspectionDate: Date,
    fontSize: number = ProtocolPdfConstants.defaultFontSize
  ) {
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      fontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    const dateText = this.datePipe.transform(inspectionDate, ProtocolPdfConstants.dateFormat);
    this.writeText(
      dateText,
      fontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX + 17,
      baseY,
      1
    );
  }

  protected addFurtherProcedureDetails(baseY: number): number {
    const signatureBlockHeight = 3 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }

    const pdfDocumentParagraph = new PdfDocumentParagraph();
    pdfDocumentParagraph.headerFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.bodyFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.positionY = baseY;
    pdfDocumentParagraph.prefix = '-';
    pdfDocumentParagraph.headerText = this.translateService.instant(
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsArAi'
    );
    pdfDocumentParagraph.paragraphTexts = [
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsArAi1'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsArAi2'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsArAi3'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsArAi4'),
    ];
    pdfDocumentParagraph.keepParagraphInSamePage = true;
    return this.writeParagraph(pdfDocumentParagraph);
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineAiAr';
    defectTableParams.addControlledArea = true;
    defectTableParams.defectCorrectionOnOddNumberedPage = true;
    defectTableParams.tvdIdOnHeader = true;
    return defectTableParams;
  }

  private addAdditionalInformationCheckboxes(baseY: number): number {
    baseY += this.getNewLine(5, 0);
    baseY = this.ensureAdditionalInformationCheckboxesInSamePage(baseY);
    const checkBox = new PdfDocumentCheckbox();
    baseY = this.addAdditionalInformationCheckboxesLine1(checkBox, baseY);
    baseY += this.getNewLine(1);
    return this.addAdditionalInformationCheckboxesLine2(checkBox, baseY);
  }

  private ensureAdditionalInformationCheckboxesInSamePage(baseY: number) {
    const additionalInformationSize = this.getNewLine(2);
    if (baseY + additionalInformationSize > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    return baseY;
  }

  private addAdditionalInformationCheckboxesLine1(
    checkBox: PdfDocumentCheckbox,
    baseY: number
  ): number {
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitFromLocation');
    checkBox.currentPositionY = baseY;
    checkBox.checkboxNumber = 1;
    checkBox.positionX = ProtocolPdfConstants.marginX + checkBox.marginCheckbox;
    let nextPositionX = this.calculateNextCheckboxPositionX(checkBox);
    this.addCheckbox(checkBox);
    checkBox.text = this.translateService.instant(
      'ProtocolPdf.LabelUnitAbsentDepositMailOrLetterSimple'
    );
    checkBox.positionX += nextPositionX + 5;
    nextPositionX = this.calculateNextCheckboxPositionX(checkBox);
    return this.addCheckbox(checkBox);
  }

  private addAdditionalInformationCheckboxesLine2(
    checkBox: PdfDocumentCheckbox,
    baseY: number
  ): number {
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelAbsent') + ':';
    checkBox.currentPositionY = baseY;
    checkBox.checkboxNumber = 1;
    checkBox.positionX = ProtocolPdfConstants.marginX + checkBox.marginCheckbox;
    let nextPositionX = this.calculateNextCheckboxPositionX(checkBox) + 9.25;
    this.addCheckbox(checkBox);
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitAbsentLetter');
    checkBox.positionX += nextPositionX + 5;
    nextPositionX = this.calculateNextCheckboxPositionX(checkBox);
    this.addCheckbox(checkBox);
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitAbsentDeposit');
    checkBox.positionX += nextPositionX + 4;
    return this.addCheckbox(checkBox);
  }

  private hasDefects(protocolPdfDto: ProtocolPdfDto): boolean {
    return (
      this.inspectionResultsService.getInspectionResultsByAccessor({
        ...protocolPdfDto,
        inspectionResultAccessor: InspectionResultAccessor.defect,
      }).size > 0
    );
  }
}
