import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  InspectionCategoryDto,
  InspectionCategoryService,
  InspectionElementDto,
  InspectionReasonDto,
  InspectionWithResultsDto,
} from '@core/api';
import { InspectionCategoryDBService } from '@core/db/inspection-category-db-service';
import { InspectionDBService } from '@core/db/inspection-db-service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, mergeMap } from 'rxjs';

@Component({
  selector: 'app-inspection-category-dialog',
  templateUrl: './inspection-category-dialog.component.html',
  styleUrls: ['./inspection-category-dialog.component.scss'],
})
export class InspectionCategoryDialogComponent implements OnInit {
  public form: FormGroup;
  public inspectionCategories: Array<InspectionCategoryDto> = [];
  public inspectionReasons: Array<InspectionReasonDto> = [];
  public inspection: InspectionWithResultsDto;
  public enableSubmit = false;

  public constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private inspectionCategoryService: InspectionCategoryService,
    private inspectionCategoryDBService: InspectionCategoryDBService,
    private inspectionDbService: InspectionDBService
  ) {}

  public get categoriesFormArray() {
    return this.form.controls.categories as FormArray;
  }

  public ngOnInit() {
    this.form = this.formBuilder.group({
      categories: new FormArray([]),
      inspectionReason: new FormControl(this.inspectionReasons[0]),
    });
    this.addCheckboxes();
  }

  public submit() {
    const selectedCategoryNumbers: string[] = this.getSelectedCategoryNumbers();
    const selectedReasonKey = this.form.value.inspectionReason.key;
    this.syncDownCategoriesAndUpdateInspection(selectedCategoryNumbers, selectedReasonKey);
  }

  public onCheckboxChange() {
    this.enableSubmit = this.getSelectedCategoryNumbers().length > 0;
  }

  private getSelectedCategoryNumbers(): string[] {
    return this.form.value.categories
      .map((checked: boolean, index: number) =>
        checked ? this.inspectionCategories[index].number : null
      )
      .filter((categoryNumber: string | null): categoryNumber is string => categoryNumber !== null);
  }

  private addCheckboxes() {
    this.inspectionCategories.forEach(() => this.categoriesFormArray.push(new FormControl(false)));
  }

  private syncDownCategoriesAndUpdateInspection(
    categoryNumbers: string[],
    inspectionReasonKey: string
  ): void {
    const apiCallObservables = categoryNumbers.map((categoryNumber: string) =>
      this.inspectionCategoryService.getInspectionElementTree(categoryNumber)
    );
    forkJoin(apiCallObservables)
      .pipe(
        mergeMap((categories: InspectionElementDto[]) => {
          const dbCallObservables = categories.map((category: InspectionElementDto) =>
            this.inspectionCategoryDBService.putInspectionCategory(category)
          );
          return forkJoin(dbCallObservables);
        })
      )
      .subscribe((success: string[]) => {
        console.log(success);
        this.updateInspection(categoryNumbers, inspectionReasonKey);
      });
  }

  private updateInspection(categoryNumbers: string[], inspectionReasonKey: string): void {
    categoryNumbers.forEach((categoryNumber: string) => {
      // No need to set FlatInspectionCategoryResultDto.results because DetailComponent.initializeInspectionResults() will do that.
      this.inspection.categoryResults.push({
        categoryNumber,
        inspectionReason: null,
        results: null,
        inspectionReasonKey
      });
    });
    this.inspectionDbService
      .putInspectionWithResults(this.inspection)
      .subscribe((success: string) => {
        console.log(success);
        this.modal.close('success');
      });
  }
}
