<div class="table-responsive table-matrix">
  <table
    class="table table-striped table-sm table-bordered table-hover bsg-table matrix"
    [ngClass]="{ 'sticky-left': isStickyCol() }">
    <thead>
      <tr>
        <th
          class="text-center element-number"
          [ngClass]="{
            'matrix-column-large': getColumnsToDisplay().length <= 1,
            'sticky-top': isStickyRow()
          }">
          <button
            *ngIf="notApplicablePointGroupsDisplayed"
            ngbPopover="{{ 'NZ-Punktegruppen ausblenden' | translate }}"
            placement="right"
            triggers="mouseenter:mouseleave"
            class="btn btn-link btn-expand"
            (click)="notApplicablePointGroupsDisplayed = !notApplicablePointGroupsDisplayed">
            <span class="fas fa-angle-double-left"></span>
          </button>
          <button
            *ngIf="!notApplicablePointGroupsDisplayed"
            ngbPopover="{{ 'NZ-Punktegruppen einblenden' | translate }}"
            placement="right"
            triggers="mouseenter:mouseleave"
            class="btn btn-link btn-expand"
            (click)="notApplicablePointGroupsDisplayed = !notApplicablePointGroupsDisplayed">
            <span class="fas fa-angle-double-right"></span>
          </button>
        </th>
        <th
          class="matrix-point-header"
          [ngClass]="{
            'matrix-column-large': getColumnsToDisplay().length <= 1,
            'sticky-top': isStickyRow()
          }">
          <h3>
            <span *ngIf="categoryDetail.categoryName">{{
              categoryDetail.categoryName | i18nText
            }}</span
            ><span>&nbsp;({{ categoryDetail.categoryNumber }})</span>
          </h3>
        </th>
        <th
          class="text-center matrix-row-result-header"
          *ngIf="displayRowPointTemplateColumn() || !getColumnsToDisplay().length"
          [ngClass]="{
            'matrix-column-large': getColumnsToDisplay().length <= 1,
            'matrix-column-large': getColumnsToDisplay().length <= 1,
            'sticky-top': isStickyRow()
          }"></th>
        <th
          class="text-center matrix-point-group-header"
          *ngFor="let column of getColumnsToDisplay()"
          [ngClass]="{
            'matrix-column-large': getColumnsToDisplay().length <= 1,
            'sticky-top': isStickyRow()
          }">
          <p>{{ column.pointGroupName | i18nText }}</p>
          <app-detail-result-dropdown
            (resultChange)="columnResultChanged(column)"
            [result]="column.pointGroupResult"
            [resultType]="column.pointGroupResult.inspectionResult"
            [dependingResults]="getDependingResultsForColumn(column)"
            [displayDefectOption]="false"
            [displayEditRemark]="false"
            [readonly]="readonly"
            [elementName]="column.pointGroupName"></app-detail-result-dropdown>
        </th>
        <th
          class="matrix-filler"
          [ngClass]="{
            'sticky-top': isStickyRow()
          }"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let point of matrix.pointsOnCategoryLevel">
        <td class="text-center">
          {{ point.pointNumber }}
        </td>
        <td
          (click)="
            hasDescription(point.pointDescription) &&
              (point.pointDescriptionDisplayed = !point.pointDescriptionDisplayed)
          "
          class="matrix-point-name"
          [ngClass]="{ clickable: hasDescription(point.pointDescription) }"
          [attr.aria-expanded]="point.pointDescriptionDisplayed"
          [attr.aria-controls]="point.pointNumber">
          <span
            *ngIf="hasDescription(point.pointDescription)"
            class="description-icon fas fa-info-circle"></span>
          <div>{{ point.pointName | i18nText }}</div>
          <div id="{{ point.pointNumber }}" [ngbCollapse]="!point.pointDescriptionDisplayed">
            <i class="element-description">{{ point.pointDescription | i18nText }}</i>
          </div>
        </td>
        <td [colSpan]="getPointsOnCategoryLevelColSpan()" class="text-center">
          <div *ngIf="point.pointResult">
            <app-detail-result-button
              *ngIf="!point.pointResult.fieldValue"
              (resultChange)="pointResultChanged(point.pointResult)"
              [result]="point.pointResult"
              [defectDescriptionSuggestions]="point.defectDescriptionSuggestions"
              [readonly]="readonly"
              [elementName]="point.pointName"
              [isFkp]="point.isFkp"></app-detail-result-button>
            <app-detail-result-field-value
              *ngIf="
                point.pointResult.fieldValue &&
                (point.pointResult.fieldValue.inspectionElementFieldType === fieldValueTypes.Date ||
                  point.pointResult.fieldValue.inspectionElementFieldType === fieldValueTypes.Text)
              "
              (resultChange)="pointResultFieldValueChanged()"
              [result]="point.pointResult"
              [readonly]="readonly"></app-detail-result-field-value>
            <app-detail-result-yes-no-button
              *ngIf="
                point.pointResult.fieldValue &&
                point.pointResult.fieldValue.inspectionElementFieldType === fieldValueTypes.YesNo
              "
              [result]="point.pointResult.fieldValue"
              (resultChange)="pointResultFieldValueChanged()"
              [readonly]="readonly"></app-detail-result-yes-no-button>
            <app-detail-result-number-field-value
              *ngIf="
                point.pointResult.fieldValue &&
                point.pointResult.fieldValue.inspectionElementFieldType === fieldValueTypes.Number
              "
              [result]="point.pointResult"
              (resultChange)="pointResultFieldValueChanged()"
              [displayRemark]="point.displayRemarkOfNumberFieldValue"
              [readonly]="readonly"></app-detail-result-number-field-value>
          </div>
        </td>
        <td class="matrix-filler"></td>
      </tr>
      <ng-container *ngFor="let row of matrix.rows">
        <tr *ngIf="row.isPointGroup" class="detail-matrix-subhead-point-group">
          <td class="text-center">{{ row.number }}</td>
          <td
            (click)="
              hasDescription(row.description) &&
                (row.descriptionDisplayed = !row.descriptionDisplayed)
            "
            class="matrix-point-name"
            [ngClass]="{ clickable: hasDescription(row.description) }"
            [attr.aria-expanded]="row.descriptionDisplayed"
            [attr.aria-controls]="row.number">
            <span
              *ngIf="hasDescription(row.description)"
              class="description-icon fas fa-info-circle"></span>
            <div>{{ row.name | i18nText }}</div>
            <div id="{{ row.number }}" [ngbCollapse]="!row.descriptionDisplayed">
              <i class="element-description">{{ row.description | i18nText }}</i>
            </div>
          </td>
          <td class="matrix-filler" *ngIf="displayRowPointTemplateColumn()"></td>
          <td *ngFor="let pointGroup of getPointGroupsOfRow(row)" class="text-center">
            <app-detail-result-dropdown
              *ngIf="getDependingResultsForPointGroup(pointGroup).length > 0"
              (resultChange)="rowPointGroupResultChanged(pointGroup)"
              [result]="pointGroup.pointGroupResult"
              [resultType]="pointGroup.pointGroupResult.inspectionResult"
              [dependingResults]="getDependingResultsForPointGroup(pointGroup)"
              [displayDefectOption]="true"
              [displayEditRemark]="false"
              [readonly]="readonly"></app-detail-result-dropdown>
          </td>
          <td class="matrix-filler"></td>
        </tr>
        <tr *ngIf="!row.isPointGroup">
          <td class="text-center">{{ row.number }}</td>
          <td
            (click)="
              hasDescription(row.description) &&
                (row.descriptionDisplayed = !row.descriptionDisplayed)
            "
            class="matrix-point-name"
            [ngClass]="{ clickable: hasDescription(row.description) }"
            [attr.aria-expanded]="row.descriptionDisplayed"
            [attr.aria-controls]="row.number">
            <span
              *ngIf="hasDescription(row.description)"
              class="description-icon fas fa-info-circle"></span>
            <div>{{ row.name | i18nText }}</div>
            <div id="{{ row.number }}" [ngbCollapse]="!row.descriptionDisplayed">
              <i class="element-description">{{ row.description | i18nText }}</i>
            </div>
          </td>
          <td *ngIf="displayRowPointTemplateColumn()" class="text-center">
            <app-detail-result-dropdown
              *ngIf="getPointsToDisplay(row)?.length > 1 && !row.isFieldValuePoint"
              (resultChange)="rowResultChanged(row)"
              [result]="row.pointResultModel"
              [resultType]="row.pointResultModel.inspectionResult"
              [dependingResults]="getDependingResultsForRow(row)"
              [displayDefectOption]="true"
              [displayEditRemark]="true"
              [readonly]="readonly"
              [elementName]="row.name"
              [defectDescriptionSuggestions]="
                row.defectDescriptionSuggestions
              "></app-detail-result-dropdown>
          </td>
          <td *ngIf="!displayRowPointTemplateColumn() && !getColumnsToDisplay().length"></td>
          <td *ngFor="let point of getPointsToDisplay(row)" class="text-center">
            <div *ngIf="point.result">
              <app-detail-result-button
                *ngIf="!point.result.fieldValue"
                (resultChange)="rowPointResultChanged(point, row.number)"
                [result]="point.result"
                [defectDescriptionSuggestions]="point.defectDescriptionSuggestions"
                [readonly]="readonly"
                [elementName]="row.name"
                [isFkp]="point.isFkp"></app-detail-result-button>
              <app-detail-result-field-value
                *ngIf="
                  point.result.fieldValue &&
                  (point.result.fieldValue.inspectionElementFieldType === fieldValueTypes.Date ||
                    point.result.fieldValue.inspectionElementFieldType === fieldValueTypes.Text)
                "
                (resultChange)="rowPointResultFieldValueChanged(point)"
                [result]="point.result"
                [readonly]="readonly"></app-detail-result-field-value>
              <app-detail-result-yes-no-button
                *ngIf="
                  point.result.fieldValue &&
                  point.result.fieldValue.inspectionElementFieldType === fieldValueTypes.YesNo
                "
                [result]="point.result.fieldValue"
                (resultChange)="rowPointResultFieldValueChanged(point)"
                [readonly]="readonly"></app-detail-result-yes-no-button>
              <app-detail-result-number-field-value
                *ngIf="
                  point.result.fieldValue &&
                  point.result.fieldValue.inspectionElementFieldType === fieldValueTypes.Number
                "
                [result]="point.result"
                (resultChange)="rowPointResultFieldValueChanged(point)"
                [displayRemark]="point.displayRemarkOfNumberFieldValue"
                [readonly]="readonly"></app-detail-result-number-field-value>
            </div>
          </td>
          <td class="matrix-filler"></td>
        </tr>
      </ng-container>
      <tr *ngIf="categoryDetail.displayCategoryResult">
        <td
          colspan="2"
          (click)="
            hasDescription(matrix.summary.categoryDescription) &&
              (matrix.summary.categoryDescriptionDisplayed =
                !matrix.summary.categoryDescriptionDisplayed)
          "
          class="matrix-point-name"
          [ngClass]="{ clickable: hasDescription(matrix.summary.categoryDescription) }"
          [attr.aria-expanded]="matrix.summary.categoryDescriptionDisplayed"
          [attr.aria-controls]="categoryDetail.categoryNumber">
          <span
            *ngIf="hasDescription(matrix.summary.categoryDescription)"
            class="description-icon fas fa-info-circle"></span>
          <h3>
            <span translate>Gesamtbeurteilung</span
            ><span>&nbsp;{{ categoryDetail.categoryName | i18nText }}</span>
          </h3>
          <div
            id="{{ categoryDetail.categoryNumber }}"
            [ngbCollapse]="!matrix.summary.categoryDescriptionDisplayed">
            <i class="element-description">{{ matrix.summary.categoryDescription | i18nText }}</i>
          </div>
        </td>
        <td
          [colSpan]="
            getColumnsToDisplay().length +
            (displayRowPointTemplateColumn() || !getColumnsToDisplay().length ? 1 : 0)
          "
          class="text-center">
          <app-detail-category-result
            (resultChange)="categoryResultChanged($event)"
            [result]="matrix.summary.categoryResult"
            [resultType]="matrix.summary.categoryResult.inspectionResult"
            [hasDefects]="hasDefects"
            [readonly]="readonly"></app-detail-category-result>
        </td>
        <td class="matrix-filler"></td>
      </tr>
    </tbody>
  </table>
</div>
