import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { PdfDocumentCheckbox } from './pdf-document-checkbox';
import { PdfDocumentParagraph } from './pdf-document-paragraph';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { Constants } from '@core/constants';

export class ProtocolPdfUrSzOwNwService extends ProtocolPdfStandardService {
  protected labelStatementTranslationKey = 'ProtocolPdf.LabelStatementUrkantone';
  protected inspection: InspectionWithResultsDto;

  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.inspection = protocolPdfDto.inspection;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader();
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderProtocol');
    baseY = this.addInspectionPersonInformation(protocolPdfDto.inspection, baseY);
    baseY = this.addInspectionHeader(protocolPdfDto.inspection, protocolPdfDto.protocol, baseY);
    const newBaseY = this.addInspectionResultsDisplayedOnTop(protocolPdfDto, baseY);
    baseY = newBaseY > baseY ? newBaseY + ProtocolPdfConstants.lineHeight : baseY;
    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    const inspectionResults = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defectOrFieldValue,
    });
    baseY = this.addInspectionResults(protocolPdfDto, inspectionResults, baseY);
    if (inspectionResults.size) {
      baseY += ProtocolPdfConstants.lineHeight;
    }
    baseY = this.addNkCategoryResultsDisplayedInProtocol(protocolPdfDto, baseY);
    baseY += ProtocolPdfConstants.lineHeight;
    baseY = this.addDocumentationCheckboxes(baseY);
    baseY += this.getNewLine(1);
    baseY = this.addCommentOfDepartment(protocolPdfDto, baseY);
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    protocolPdfDto.protocol.fees.displayFeesOnProtocol = 0;
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY, false);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(3);
    baseY = this.addStatement(protocolPdfDto.protocol, baseY);
    baseY = this.addFurtherProcedureDetails(baseY);
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        inspectionResults
      );
    }
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save('kontrolle-' + protocolPdfDto.inspection.inspectionId + '.pdf');
    this.translateService.use(originalLanguage);
  }

  protected addPageHeader(): number {
    this.pdfDocument.setFont('helvetica');
    const imageData = this.getImageData(Constants.imageUrSzOwNw);
    this.addImage(
      imageData,
      'png',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage,
      ProtocolPdfConstants.maxXValue - ProtocolPdfConstants.marginX - 17,
      13.5
    );
    return 40;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    baseY += 3;

    const multiLineTextResponsiblePersonPresent = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant('ProtocolPdf.TextResponsiblePersonPresentUrk'),
      ProtocolPdfConstants.maxLineWidth
    );

    const signatureBlockHeight =
      (multiLineTextResponsiblePersonPresent.length + 6) * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }

    this.addAcroCheckBox(3, ProtocolPdfConstants.marginX, baseY);
    const separationCheckboxAndText = 5.25;
    const positionX = ProtocolPdfConstants.marginX + separationCheckboxAndText;
    baseY = this.writeMultiLineText(
      multiLineTextResponsiblePersonPresent,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      positionX,
      baseY,
      0
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;

    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    const dateText = this.datePipe.transform(
      inspection.inspectionDate,
      ProtocolPdfConstants.dateFormat
    );
    this.writeText(
      dateText,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX + 17,
      baseY,
      1
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;

    baseY = this.addAdditionalInformationCheckboxes(baseY);
    return baseY;
  }

  protected addFurtherProcedureDetails(baseY: number): number {
    const signatureBlockHeight = 3 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    const pdfDocumentParagraph = new PdfDocumentParagraph();
    pdfDocumentParagraph.headerFontSize = ProtocolPdfConstants.defaultFontSize;
    pdfDocumentParagraph.headerFontType = ProtocolPdfConstants.boldFontType;
    pdfDocumentParagraph.bodyFontSize = ProtocolPdfConstants.smallFontSize;
    pdfDocumentParagraph.positionY = baseY;
    pdfDocumentParagraph.prefix = '-';
    pdfDocumentParagraph.headerText =
      this.translateService.instant('ProtocolPdf.TextHeaderFurtherProcedureDetailsUrk') + ':';
    pdfDocumentParagraph.paragraphTexts = [
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsUrk1'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsUrk2'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsUrk3'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsUrk4'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsUrk5'),
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsUrk6'),
    ];
    pdfDocumentParagraph.keepParagraphInSamePage = true;
    return this.writeParagraph(pdfDocumentParagraph);
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineUrk';
    defectTableParams.addControlledArea = true;
    defectTableParams.defectCorrectionOnOddNumberedPage = true;
    defectTableParams.labelRemarks = ['ProtocolPdf.LabelDefectCorrectionRemarksUnit'];
    defectTableParams.showUnitNameOnFooter = true;
    defectTableParams.showStatementTitle = true;
    defectTableParams.confirmationDefectCorrectionWithDate = true;
    defectTableParams.inspectionDetailsOnHeader = false;
    defectTableParams.inspectionDateOnHeader = false;
    defectTableParams.useAcroFormsOnProtocol = true;
    return defectTableParams;
  }

  protected getDefectCorrectionAddress(baseY: number): number {
    this.addPageHeader();

    const margin2ndColumn = ProtocolPdfConstants.marginX + 100;
    baseY += 4 * ProtocolPdfConstants.lineHeight;

    this.writeText(
      this.inspection.unitTvdId,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + margin2ndColumn,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      'Veterinärdienst der Urkantone',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;

    this.writeText(
      this.inspection.unitName,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + margin2ndColumn,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      'Föhneneichstrasse 15',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;

    const address =
      (this.inspection.unitStreetName ? this.inspection.unitStreetName : '') +
      (this.inspection.unitStreetNumber ? ' ' + this.inspection.unitStreetNumber : '');
    this.writeText(
      address,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + margin2ndColumn,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      'Postfach 363',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;

    const postalZoneTown =
      (this.inspection.unitPostalZone ? this.inspection.unitPostalZone + ' ' : '') +
      (this.inspection.unitTown ? this.inspection.unitTown : '');
    this.writeText(
      postalZoneTown,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + margin2ndColumn,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      '6440 Brunnen',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );

    baseY += 5 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  private addAdditionalInformationCheckboxes(baseY: number): number {
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitCopyPending') + ':',
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;

    const checkBox = new PdfDocumentCheckbox();
    checkBox.currentPositionY = baseY;
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitAbsentEmailSimple');
    checkBox.extraSizePositionX = 12;
    this.addCheckbox(checkBox);

    baseY += ProtocolPdfConstants.lineHeight;
    checkBox.currentPositionY = baseY;
    checkBox.text = this.translateService.instant('ProtocolPdf.LabelUnitAbsentLetterSimple');
    this.addCheckbox(checkBox);
    return baseY;
  }
}
