import { UploadInspectionDto } from '../../dtos/uploadInspectionDto';
import { Injectable } from '@angular/core';
import { FlatInspectionCategoryResultDto } from '../api/model/flatInspectionCategoryResultDto';
import { FlatInspectionResultDto } from '../api/model/flatInspectionResultDto';
import { InspectionElementDto } from '../api/model/inspectionElementDto';
import { InspectionResultType } from '../api/model/inspectionResultType';
import { InspectionResultElementType } from '../api/model/inspectionResultElementType';
import { InspectionElementFieldType } from '../api/model/inspectionElementFieldType';
import { InspectionWithResultsDto } from '../api/model/inspectionWithResultsDto';
import { UpdateInspectionWithResultsDto } from '../api/model/updateInspectionWithResultsDto';

@Injectable({ providedIn: 'root' })
export class InspectionUploadPreparationService {
  public prepareInspectionsForUpload(
    inspections: InspectionWithResultsDto[],
    inspectionCategories: InspectionElementDto[]
  ): UploadInspectionDto[] {
    const uploadInspectionDtos = new Array<UploadInspectionDto>();
    inspections.forEach((inspectionWithResults) => {
      const uploadInspectionDto: UploadInspectionDto = {
        inspection: inspectionWithResults,
        uploadInspection: this.getUpdateInspectionWithResultsDto(inspectionWithResults),
        notDefinedPointsPerCategory: [],
      };
      const refinedResults = this.refineInspectionResults(
        inspectionWithResults.categoryResults,
        inspectionCategories
      );
      refinedResults.forEach((refinedResult) => {
        uploadInspectionDto.uploadInspection.categoryResults.push(refinedResult[0]);
        uploadInspectionDto.notDefinedPointsPerCategory.push([
          refinedResult[0].categoryNumber,
          refinedResult[1],
        ]);
      });

      uploadInspectionDtos.push(uploadInspectionDto);
    });

    return uploadInspectionDtos;
  }

  private getUpdateInspectionWithResultsDto(
    inspectionWithResults: InspectionWithResultsDto
  ): UpdateInspectionWithResultsDto {
    return {
      categoryResults: [],
      inspectionDate: inspectionWithResults.inspectionDate,
      inspectionKey: inspectionWithResults.inspectionKey,
      inspectionRemark: inspectionWithResults.inspectionRemark,
      inspectionStatus: inspectionWithResults.inspectionStatus.asEnum,
      inspectionStyleKey: inspectionWithResults.inspectionStyle.key,
    };
  }

  private refineInspectionResults(
    inspectionCategoryResults: FlatInspectionCategoryResultDto[],
    inspectionCategories: InspectionElementDto[]
  ): [FlatInspectionCategoryResultDto, number][] {
    return inspectionCategoryResults.map((inspectionCategoryResult) => {
      const inspectionCategoryDefinition = inspectionCategories.find(
        (category) => category.number === inspectionCategoryResult.categoryNumber
      );
      return this.refineInspectionResultsForCategory(
        inspectionCategoryResult,
        inspectionCategoryDefinition
      );
    });
  }

  private refineInspectionResultsForCategory(
    inspectionCategoryResult: FlatInspectionCategoryResultDto,
    category: InspectionElementDto
  ): [FlatInspectionCategoryResultDto, number] {
    const refinedResults = this.getRelevantInspectionResultsAndNumberOfNotDefinedPoints(
      category,
      inspectionCategoryResult.results
    );
    return [
      {
        categoryNumber: inspectionCategoryResult.categoryNumber,
        results: refinedResults[0],
        inspectionReason: inspectionCategoryResult.inspectionReason,
        inspectionReasonKey: inspectionCategoryResult.inspectionReasonKey
      },
      refinedResults[1],
    ];
  }

  private getRelevantInspectionResultsAndNumberOfNotDefinedPoints(
    inspectionElement: InspectionElementDto,
    results: FlatInspectionResultDto[]
  ): [FlatInspectionResultDto[], number] {
    const relevantResults = new Array<FlatInspectionResultDto>();
    let notDefinedPointResults = 0;
    const currentElementResult = results.find(
      (result) => inspectionElement.key === result.inspectionElementKey
    );
    if (
      inspectionElement.type === InspectionResultElementType.Category ||
      inspectionElement.type === InspectionResultElementType.PointGroup
    ) {
      if (
        currentElementResult &&
        currentElementResult.inspectionResult &&
        currentElementResult.inspectionResult !== InspectionResultType.NotDefined
      ) {
        relevantResults.push(currentElementResult);
      }
      if (
        inspectionElement.childElements &&
        (!currentElementResult ||
          (currentElementResult.inspectionResult !== InspectionResultType.NotApplicable &&
            currentElementResult.inspectionResult !== InspectionResultType.NotControlled))
      ) {
        inspectionElement.childElements.forEach((childElement) => {
          const relevantChildResults = this.getRelevantInspectionResultsAndNumberOfNotDefinedPoints(
            childElement,
            results
          );
          relevantResults.push.apply(relevantResults, relevantChildResults[0]);
          notDefinedPointResults += relevantChildResults[1];
        });
      }
    } else {
      if (this.hasPointValue(inspectionElement, currentElementResult)) {
        relevantResults.push(currentElementResult);
      } else {
        notDefinedPointResults++;
      }
    }

    return [relevantResults, notDefinedPointResults];
  }

  private hasPointValue(pointElement: InspectionElementDto, result: FlatInspectionResultDto) {
    let hasPointValue = true;
    if (!result) {
      hasPointValue = false;
    } else {
      if (!pointElement.fieldType && result.inspectionResult === InspectionResultType.NotDefined) {
        hasPointValue = false;
      } else if (pointElement.fieldType) {
        if (!result.fieldValue) {
          hasPointValue = false;
        } else if (
          (pointElement.fieldType === InspectionElementFieldType.Date &&
            result.fieldValue.dateValue == null) ||
          (pointElement.fieldType === InspectionElementFieldType.Number &&
            result.fieldValue.numberValue == null &&
            !result.remark) ||
          (pointElement.fieldType === InspectionElementFieldType.Text &&
            (result.fieldValue.textValue == null || result.fieldValue.textValue === '')) ||
          (pointElement.fieldType === InspectionElementFieldType.YesNo &&
            result.fieldValue.yesNoValue == null)
        ) {
          hasPointValue = false;
        }
      }
    }
    return hasPointValue;
  }
}
