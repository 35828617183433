import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InspectionCategoryDetailDto } from '../dtos/inspectionCategoryDetailDto';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';
import { ActivatedRoute } from '@angular/router';
import { Point } from '../core/api/model/point';
import { ClientConfigDBService } from '../core/db/client-config-db-service';
import {
  InspectionElementDto,
  I18NText,
  FlatInspectionResultDto,
  TextFieldValueSuggestion,
} from '../core/api/model/models';
import { PointConfig } from '../core/api/model/pointConfig';
import { InspectionResultType } from '../core/api/model/inspectionResultType';
import { PointGroupType } from '../core/api/model/pointGroupType';
import { InspectionResultElementType } from '../core/api/model/inspectionResultElementType';
import { InspectionCategoryCacheService } from '@core/cache/inspection-category-cache-service';

@Component({
  template: '',
})
export class DetailBaseComponent implements OnInit {
  @Input()
  public categoryDetail: InspectionCategoryDetailDto;

  @Input()
  public inspectionCategory: InspectionElementDto;

  @Input()
  public notApplicableCategoriesDisplayed = false;

  @Input()
  public readonly = false;

  @Output()
  public save: EventEmitter<any> = new EventEmitter();

  public hasDefects: boolean;

  protected pointConfig: PointConfig;

  protected copyNumberValueEnabled: boolean;

  private hasStickyRows: boolean;

  private hasStickyCols: boolean;

  public constructor(
    private i18nTextPipe: I18nTextPipe,
    private route: ActivatedRoute,
    private clientConfigDBService: ClientConfigDBService,
    private inspectionCategoryCacheService: InspectionCategoryCacheService
  ) {}

  public hasDescription(description: I18NText): boolean {
    return this.i18nTextPipe.transform(description) ? true : false;
  }

  public ngOnInit(): void {
    this.hasDefects = this.categoryDetail.categoryResult.results.some(
      (result) =>
        result.inspectionElementKey !== this.inspectionCategory.key &&
        result.inspectionResult === InspectionResultType.Defect
    );
    this.pointConfig = this.route.snapshot.data['pointConfig'] as PointConfig;
    this.clientConfigDBService.getClientConfig().subscribe(
      (clientConfig) => {
        if (clientConfig) {
          this.hasStickyRows = clientConfig?.resultWithStickyRows ?? false;
          this.hasStickyCols = clientConfig?.resultWithStickyCols ?? false;
        }
      },
      () => {}
    );
    this.copyNumberValueEnabled =
      this.inspectionCategoryCacheService.getCopyNumberValueEnabled(
        this.categoryDetail.categoryNumber
      );
  }

  protected handleDefects(): void {
    const categoryResult = this.categoryDetail.categoryResult.results.find(
      (result) => result.inspectionElementKey === this.inspectionCategory.key
    );
    this.hasDefects = this.categoryDetail.categoryResult.results.some(
      (result) =>
        result.inspectionElementKey !== this.inspectionCategory.key &&
        result.inspectionResult === InspectionResultType.Defect
    );
    if (categoryResult.inspectionResult === InspectionResultType.Defect && !this.hasDefects) {
      categoryResult.inspectionResult = InspectionResultType.NotDefined;
    } else if (categoryResult.inspectionResult !== InspectionResultType.Defect && this.hasDefects) {
      categoryResult.inspectionResult = InspectionResultType.Defect;
    }
  }

  protected getInspectionElement(
    currentElement: InspectionElementDto,
    key: string
  ): InspectionElementDto {
    let inspectionElement: InspectionElementDto = null;
    if (currentElement.key === key) {
      inspectionElement = currentElement;
    } else if (currentElement.childElements) {
      currentElement.childElements.forEach((childElement) => {
        const elementFound = this.getInspectionElement(childElement, key);
        if (elementFound) {
          inspectionElement = elementFound;
        }
      });
    }

    return inspectionElement;
  }

  protected getResult(key: string): FlatInspectionResultDto {
    return this.categoryDetail.categoryResult.results.find(
      (result) => result.inspectionElementKey === key
    );
  }

  protected propagateResultToChildren(
    inspectionElement: InspectionElementDto,
    inspectionResult: InspectionResultType,
    overrideNotApplicable: boolean = false
  ): void {
    if (inspectionElement.childElements) {
      inspectionElement.childElements.forEach((childInspectionElement) => {
        if (childInspectionElement.pointGroupType !== PointGroupType.SubheadWithoutDataEntry) {
          const existingResult = this.getResult(childInspectionElement.key);
          if (!existingResult.fieldValue) {
            if (
              overrideNotApplicable ||
              existingResult.inspectionResult !== InspectionResultType.NotApplicable
            ) {
              existingResult.inspectionResult = inspectionResult;
              existingResult.remark = null;
              existingResult.defectDescription = null;
            }
          }
        }
        this.propagateResultToChildren(
          childInspectionElement,
          inspectionResult,
          overrideNotApplicable
        );
      });
    }
  }

  protected isResultNotApplicableOrNotControlled(result: FlatInspectionResultDto): boolean {
    return (
      result.inspectionResult === InspectionResultType.NotApplicable ||
      result.inspectionResult === InspectionResultType.NotControlled
    );
  }

  protected resetFieldValue(result: FlatInspectionResultDto) {
    if (result.fieldValue) {
      result.fieldValue.dateValue = null;
      result.fieldValue.numberValue = null;
      result.fieldValue.textValue = null;
      result.fieldValue.yesNoValue = null;
    }
  }

  protected getDefectDescriptionSuggestions(
    pointGroupNumber: string,
    pointNumber: string
  ): I18NText[] {
    let defectDescriptionSuggestions: I18NText[] = null;
    if (this.pointConfig && this.pointConfig.points) {
      const pointConfig: Point[] = this.getPointConfig(pointNumber, pointGroupNumber);
      if (pointConfig.length > 0 && pointConfig[0].defectDescriptionSuggestions) {
        defectDescriptionSuggestions = pointConfig[0].defectDescriptionSuggestions.map((d) => ({
          germanText: d.textDe,
          frenchText: d.textFr,
          italianText: null,
        }));
      }
    }
    return defectDescriptionSuggestions;
  }

  protected getTextFieldValueSuggestions(
    pointGroupNumber: string,
    pointNumber: string
  ): I18NText[] {
    let textFieldValueSuggestions: I18NText[] = null;
    if (this.pointConfig && this.pointConfig.points) {
      const pointConfig: Point[] = this.getPointConfig(pointNumber, pointGroupNumber);
      if (pointConfig.length > 0 && pointConfig[0].textFieldValueSuggestions) {
        textFieldValueSuggestions = pointConfig[0].textFieldValueSuggestions.map(
          (d: TextFieldValueSuggestion) => ({
            germanText: d.textDe,
            frenchText: d.textFr,
            italianText: null,
          })
        );
      }
    }
    return textFieldValueSuggestions;
  }

  protected getIsTextSetToReadonly(pointGroupNumber: string, pointNumber: string): boolean {
    let isTextReadonly = false;
    if (this.pointConfig && this.pointConfig.points) {
      const pointConfig: Point[] = this.getPointConfig(pointNumber, pointGroupNumber);
      if (pointConfig.length > 0) {
        isTextReadonly = pointConfig.every((v) => v.isEditable === false);
      }
    }
    return isTextReadonly;
  }

  protected getDisplayRemarkOfNumberFieldValue(
    pointGroupNumber: string,
    pointNumber: string
  ): boolean {
    if (this.pointConfig && this.pointConfig.points) {
      const pointConfig: Point[] = this.getPointConfig(pointNumber, pointGroupNumber);
      if (pointConfig.length > 0 && pointConfig[0].displayRemarkOfNumberFieldValue === true) {
        return true;
      }
    }
    return false;
  }

  protected isStickyRow(): boolean {
    return this.hasStickyRows;
  }

  protected isStickyCol(): boolean {
    return this.hasStickyCols;
  }

  protected setCategoryResultIfAllPointsAreSame(
    categoryResult: FlatInspectionResultDto,
    inspectionResult: InspectionResultType
  ): boolean {
    const points = this.getAllPointsOf(this.inspectionCategory);
    const childResults = this.categoryDetail.categoryResult.results.filter((result) =>
      points.find((p) => p.key === result.inspectionElementKey && !p.fieldType)
    );
    const childResultsWithSameResult = childResults.filter(
      (result) => result.inspectionResult === inspectionResult
    );
    if (childResults.length === childResultsWithSameResult.length) {
      categoryResult.inspectionResult = inspectionResult;
      return true;
    }
    return false;
  }

  private getPointConfig(pointNumber: string, pointGroupNumber: string): Point[] {
    let pointConfig: Point[];
    const pointConfigCategoryPoint = this.pointConfig.points.filter(
      (p) => p.categoryNumber === this.inspectionCategory.number && p.pointNumber === pointNumber
    );
    pointConfig = pointConfigCategoryPoint.filter((p) => p.pointGroupNumber === pointGroupNumber);
    if (pointConfig.length === 0) {
      pointConfig = pointConfigCategoryPoint.filter((p) => p.pointGroupNumber === null);
    }
    return pointConfig;
  }

  private getAllPointsOf(inspectionElement: InspectionElementDto): InspectionElementDto[] {
    const childElements = new Array<InspectionElementDto>();
    if (
      (inspectionElement.type === InspectionResultElementType.PointGroup ||
        inspectionElement.type === InspectionResultElementType.Category) &&
      inspectionElement.childElements
    ) {
      inspectionElement.childElements.forEach((child) => {
        const childresults = this.getAllPointsOf(child);
        childresults.forEach((r) => childElements.push(r));
      });
    }
    if (inspectionElement.type === InspectionResultElementType.Point) {
      childElements.push(inspectionElement);
    }
    return childElements;
  }
}
