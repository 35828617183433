import { jsPDF, AcroFormCheckBox, AcroFormTextField } from 'jspdf';
import { ProtocolDto } from '../../dtos/protocolDto';
import { TranslateService } from '@ngx-translate/core';
import { ProtocolPdfResultDto } from '../../dtos/ProtocolPdfResultDto';
import { InspectionCategoryCacheService } from '../cache/inspection-category-cache-service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolFeesDto } from '../../dtos/ProtocolFeesDto';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ProtocolPdfAggregatedResultDto } from 'src/app/dtos/protocolPdfAggregatedResultDto';
import * as _ from 'underscore';
import '../../core/util/string.extension';
import { PdfDocumentText } from './pdf-document-text';
import { DefectTableParams } from './protocol-defect-table-params';
import { PdfDocumentCheckbox } from './pdf-document-checkbox';
import { PdfDocumentParagraph } from './pdf-document-paragraph';
import { I18nTextPipe } from '../pipes/i18n-text.pipe';
import { ImagesDto } from 'src/app/dtos/imagesDto';
import { InspectionResultAccessor } from './inspection-result-accessor';
import {
  FlatInspectionCategoryResultDto,
  FlatInspectionResultDto,
  I18NText,
  InspectionElementDto,
  InspectionResultType,
  InspectionWithResultsDto,
} from '@core/api';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { InspectionResultForCategoryDto } from 'src/app/dtos/inspectionResultForCategoryDto';
import { InspectionResultsService } from './inspection-results-service';
import { PdfField } from './pdf-field';
import { ProtocolPdfCategoryWithoutDefectDto } from 'src/app/dtos/protocolPdfCategoryWithoutDefectDto';
import { ProtocolPdfServiceConstructorParams } from './protocol-pdf-service-constructor-params';

export abstract class ProtocolPdfStandardService {
  protected pdfDocument: jsPDF;
  protected nbPages = 1;
  protected labelStatementTranslationKey = 'ProtocolPdf.LabelStatement';
  protected translateService: TranslateService;
  protected inspectionCategoryCacheService: InspectionCategoryCacheService;
  protected i18nTextPipe: I18nTextPipe;
  protected datePipe: DatePipe;
  protected decimalPipe: DecimalPipe;
  protected pdfImages: ImagesDto;
  protected inspectionResultsService: InspectionResultsService;

  public constructor(protocolPdfServiceConstructorParams: ProtocolPdfServiceConstructorParams) {
    this.translateService = protocolPdfServiceConstructorParams.translateService;
    this.inspectionCategoryCacheService =
      protocolPdfServiceConstructorParams.inspectionCategoryCacheService;
    this.i18nTextPipe = protocolPdfServiceConstructorParams.i18nTextPipe;
    this.datePipe = protocolPdfServiceConstructorParams.datePipe;
    this.decimalPipe = protocolPdfServiceConstructorParams.decimalPipe;
    this.pdfImages = protocolPdfServiceConstructorParams.pdfImages;
    this.inspectionResultsService = protocolPdfServiceConstructorParams.inspectionResultsService;
  }

  protected getNewLine(linesNumber: number, extraSizePerLine: number = 0): number {
    return linesNumber * (5 + extraSizePerLine);
  }

  protected addInspectionTitle(baseY: number, titleText: string): number {
    this.writeText(
      this.translateService.instant(titleText),
      ProtocolPdfConstants.bigFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY
    );
    return (baseY += this.getNewLine(1, 2));
  }

  protected addInspectionPersonInformation(
    inspection: InspectionWithResultsDto,
    baseY: number,
    boldTitle: boolean = false
  ): number {
    const margin2ndColumn = 47;
    const fontTypeTitle = boldTitle
      ? ProtocolPdfConstants.boldFontType
      : ProtocolPdfConstants.defaultFontType;
    this.writeText(
      this.translateService.instant('ProtocolPdf.SublabelInspectorD'),
      ProtocolPdfConstants.smallFontSize,
      fontTypeTitle,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.SublabelInspectorFiller') +
        (inspection.inspectorName ? inspection.inspectorName : ''),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - margin2ndColumn - 1,
      margin2ndColumn,
      baseY,
      1
    );
    return (baseY += ProtocolPdfConstants.lineHeight + 2);
  }

  protected addInspectionHeader(
    inspection: InspectionWithResultsDto,
    protocol: ProtocolDto,
    baseY: number,
    extendedVersion: boolean = true
  ): number {
    const margin2ndColumn = 45;
    const margin3rdColumn = 140;
    const margin4thColumn = 160;

    const baseYFirstColumn = this.addInspectionHeaderFirstColumn(
      inspection,
      extendedVersion,
      protocol,
      margin2ndColumn,
      margin3rdColumn,
      baseY
    );

    const baseYSecondColumn = this.addInspectionHeaderSecondColumn(
      inspection,
      extendedVersion,
      protocol,
      margin3rdColumn,
      margin4thColumn,
      baseY
    );

    baseY = Math.max(baseYFirstColumn, baseYSecondColumn) + 2 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addInspectionResults(
    protocolPdf: ProtocolPdfDto,
    inspectionResults: Map<string, Map<string, ProtocolPdfResultDto[]>>,
    baseY: number
  ): number {
    const defectTableParams = this.getDefectTableParams();
    const hasResultDefects = this.hasResultDefects(inspectionResults);
    const inspectionResultForCategoryDto = this.createInspectionResultForCategory(
      inspectionResults,
      defectTableParams
    );
    if (hasResultDefects) {
      baseY = this.calculateDefectsTableHeightWithTitle(
        baseY,
        inspectionResults,
        inspectionResultForCategoryDto
      );
    }
    this.addInspectionResultLabel(protocolPdf, baseY);
    baseY = this.addInspectionResultsTitle(protocolPdf, hasResultDefects, baseY, defectTableParams);
    if (inspectionResults.size <= 0) {
      return baseY;
    }
    baseY = this.addInspectionResultForCategory(inspectionResultForCategoryDto, baseY);
    return baseY;
  }

  protected addLine(baseY): void {
    this.pdfDocument.setLineWidth(0.3);
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
  }

  protected addCheckbox(checkBoxText: PdfDocumentCheckbox): number {
    if (checkBoxText.positionX === undefined || checkBoxText.checkboxNumber > 1) {
      checkBoxText.setPositionX();
    }
    checkBoxText.positionY = checkBoxText.currentPositionY;
    checkBoxText.fontSize = ProtocolPdfConstants.smallFontSize;
    checkBoxText.multilineText = [];
    if (checkBoxText.maxTextWidth <= 0) {
      checkBoxText.maxTextWidth = checkBoxText.marginText - checkBoxText.marginCheckbox;
    }
    checkBoxText.maxNewTextLines = 0;
    if (
      checkBoxText.positionY + 1 * ProtocolPdfConstants.lineHeight >
      ProtocolPdfConstants.maxYValue
    ) {
      checkBoxText.positionY = this.newPage();
    }
    const checkboxPositionX = checkBoxText.positionX - checkBoxText.marginCheckbox - 1;
    this.addAcroCheckBox(3, checkboxPositionX, checkBoxText.positionY + 0.5);
    return this.writeTextWithDocumentText(checkBoxText);
  }

  protected calculateNextCheckboxPositionX(checkBoxText: PdfDocumentCheckbox): number {
    const textWidth = this.pdfDocument.getTextWidth(checkBoxText.text);
    return textWidth + checkBoxText.marginCheckbox * 2;
  }

  protected addAcroTextbox(field: PdfField): void {
    const textbox = new AcroFormTextField();
    textbox.width = field.width;
    textbox.height = field.height ?? ProtocolPdfConstants.lineHeight;
    textbox.fontSize = ProtocolPdfConstants.smallFontSize;
    textbox.maxFontSize = ProtocolPdfConstants.smallFontSize;
    textbox.multiline = field.height > ProtocolPdfConstants.lineHeight;
    field.y -= ProtocolPdfConstants.lineHeight - 1.15;
    textbox.y = field.y;
    textbox.x = field.x;
    textbox.fontSize = ProtocolPdfConstants.smallFontSize;
    this.pdfDocument.addField(textbox);
  }

  protected writeParagraph(paragraph: PdfDocumentParagraph) {
    const maxDetailWidth = ProtocolPdfConstants.maxLineWidth - 3;
    let baseY = paragraph.positionY;
    if (paragraph.keepParagraphInSamePage) {
      const totalLines = this.getParagraphTotalMultilines(paragraph);
      const totalHeight =
        totalLines * ProtocolPdfConstants.lineHeight + 3 * ProtocolPdfConstants.lineHeight;
      const baseYWithTotalLines = paragraph.positionY + totalHeight;
      if (baseYWithTotalLines > ProtocolPdfConstants.maxYValue) {
        baseY = this.newPage();
      }
    }

    if (paragraph.headerText !== null) {
      const headerTextSection = new PdfDocumentText();
      headerTextSection.text = paragraph.headerText;
      headerTextSection.multilineText = [];
      headerTextSection.fontSize = paragraph.headerFontSize;
      headerTextSection.fontType = paragraph.headerFontType;
      headerTextSection.maxTextWidth = ProtocolPdfConstants.maxLineWidth;
      headerTextSection.positionX = ProtocolPdfConstants.marginX;
      headerTextSection.positionY = baseY;
      headerTextSection.maxNewTextLines = 1;
      baseY = this.writeTextWithDocumentText(headerTextSection);
    }

    paragraph.paragraphTexts.forEach((text) => {
      const multiLineTextSection = new PdfDocumentText();
      multiLineTextSection.text =
        paragraph.prefix !== undefined && paragraph.prefix !== null
          ? `${paragraph.prefix} ${text}`
          : text;
      multiLineTextSection.fontSize = paragraph.bodyFontSize;
      multiLineTextSection.maxTextWidth = maxDetailWidth;
      multiLineTextSection.positionX = ProtocolPdfConstants.marginX;
      multiLineTextSection.positionY = baseY + ProtocolPdfConstants.lineHeight;
      multiLineTextSection.multiLinesPrefix = ' ';
      baseY = this.writeTextWithDocumentText(multiLineTextSection);
    });

    return baseY;
  }

  protected addDocumentationCheckboxes(
    baseY: number,
    addOtherDocumentationCheckbox: boolean = true,
    addPhotoCheckbox: boolean = true
  ): number {
    const linesAfterCheckboxes = this.getNewLine(1);
    if (baseY + linesAfterCheckboxes > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    const marginCheckbox = 5;
    const marginFirstText = ProtocolPdfConstants.marginX + marginCheckbox;
    const marginSecondCheckbox = marginFirstText + 20;
    const marginSecondText = marginSecondCheckbox + marginCheckbox;
    const marginThirdCheckbox = marginSecondText + 100;
    if (addPhotoCheckbox) {
      this.addAcroCheckBox(3, ProtocolPdfConstants.marginX, baseY);
      this.writeText(
        this.translateService.instant('ProtocolPdf.LabelDocumentationPhotos'),
        ProtocolPdfConstants.smallFontSize,
        ProtocolPdfConstants.defaultFontType,
        marginSecondCheckbox - marginFirstText - 1,
        marginFirstText,
        baseY
      );
    }
    if (addOtherDocumentationCheckbox) {
      this.addAcroCheckBox(3, marginSecondCheckbox, baseY);
      this.writeText(
        this.translateService.instant('ProtocolPdf.LabelDocumentationOther'),
        ProtocolPdfConstants.smallFontSize,
        ProtocolPdfConstants.defaultFontType,
        marginThirdCheckbox - marginSecondCheckbox,
        marginSecondText,
        baseY
      );
    }
    return baseY + linesAfterCheckboxes;
  }

  protected addAcroCheckBox(heightAndWidth: number, x: number, y: number) {
    const checkBox = new AcroFormCheckBox();
    checkBox.appearanceState = 'Off';
    checkBox.maxFontSize = ProtocolPdfConstants.smallFontSize;
    checkBox.showWhenPrinted = true;
    checkBox.fieldName = `checkbox${x.toString().replace('.', '')}${y.toString().replace('.', '')}`;
    checkBox.readOnly = false;
    checkBox.height = heightAndWidth;
    checkBox.width = heightAndWidth;
    checkBox.x = x;
    checkBox.y = y - heightAndWidth;
    this.pdfDocument.rect(x, y - heightAndWidth, heightAndWidth, heightAndWidth);
    this.pdfDocument.addField(checkBox);
  }

  protected addCommentOfDepartment(
    protocolPdfDto: ProtocolPdfDto,
    baseY: number,
    translationKeyLabel?: string
  ): number {
    if (
      protocolPdfDto.inspection.inspectionRemark &&
      protocolPdfDto.protocol.displayInspectionRemarkInProtocolPdf
    ) {
      const margin2ndColumn = ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth;
      const multiLineComment = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        protocolPdfDto.inspection.inspectionRemark,
        ProtocolPdfConstants.maxXValue - margin2ndColumn
      );
      const nbLines = Math.max(1, multiLineComment.length) + 1;
      baseY += this.getNewLine(1);
      if (
        baseY + (nbLines + 1) * ProtocolPdfConstants.lineHeight >
        ProtocolPdfConstants.maxYValue
      ) {
        baseY = this.newPage();
      }

      const labelKey =
        translationKeyLabel !== undefined && translationKeyLabel !== null
          ? translationKeyLabel
          : 'ProtocolPdf.LabelCommentOfDepartment';

      const labelText = this.translateService.instant(labelKey);
      this.writeText(
        labelText,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.boldFontType,
        ProtocolPdfConstants.firstColumnWidth - 1,
        ProtocolPdfConstants.marginX,
        baseY,
        1
      );
      baseY = this.writeMultiLineText(
        multiLineComment,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        margin2ndColumn,
        baseY
      );
      baseY += ProtocolPdfConstants.lineHeight + this.getNewLine(1);
    }
    return baseY;
  }

  protected addFurtherAction(protocol: ProtocolDto, baseY: number): number {
    const multiLineFurtherActionText = this.getMultiLineFurtherActionText(protocol);
    const margin2ndColumn = ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth;
    const nbLines = Math.max(1, multiLineFurtherActionText.length);
    if (baseY + nbLines * ProtocolPdfConstants.lineHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelFurtherAction'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.firstColumnWidth - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    if (multiLineFurtherActionText.length) {
      baseY = this.writeMultiLineText(
        multiLineFurtherActionText,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        margin2ndColumn,
        baseY
      );
    }
    baseY += this.getNewLine(1, 3);
    return baseY;
  }

  protected addStatement(protocol: ProtocolDto, baseY: number): number {
    if (this.hasStatement(protocol)) {
      const listPrefix = ' - ';
      const marginText = 5;
      let multiLineRemark;

      if (protocol.statement.remark) {
        multiLineRemark = this.getMultiLineText(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          this.translateService.instant('ProtocolPdf.TextStatementRemarks') +
            protocol.statement.remark,
          ProtocolPdfConstants.maxLineWidth - marginText - 1
        );
      } else {
        multiLineRemark = this.getMultiLineText(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          this.translateService.instant('ProtocolPdf.TextStatementRemarks') +
            this.translateService.instant('ProtocolPdf.TextStatementNoRemarks'),
          ProtocolPdfConstants.maxLineWidth - marginText - 1
        );
      }

      const multiLineRemarkLines = multiLineRemark.length;
      const statementTitleHeight = this.getDefectTableParams().showStatementTitle
        ? ProtocolPdfConstants.lineHeight
        : 0;
      const statementLinesHeight =
        this.getStatementLinesHeight(protocol, multiLineRemarkLines) + statementTitleHeight;

      if (baseY + statementLinesHeight > ProtocolPdfConstants.maxYValue) {
        baseY = this.newPage();
      }

      if (this.getDefectTableParams().showStatementTitle) {
        this.writeText(
          this.translateService.instant('ProtocolPdf.Statement') + ':',
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.boldFontType,
          ProtocolPdfConstants.maxXValue,
          ProtocolPdfConstants.marginX,
          baseY,
          1
        );
        baseY += ProtocolPdfConstants.lineHeight;
      }

      this.writeText(
        this.translateService.instant(this.labelStatementTranslationKey),
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.maxLineWidth - 1,
        ProtocolPdfConstants.marginX,
        baseY,
        1
      );
      baseY += ProtocolPdfConstants.lineHeight;

      if (protocol.statement.correspondToFacts) {
        baseY = this.writeText(
          this.translateService.instant('ProtocolPdf.TextStatementCorrespontToFacts'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.maxLineWidth - marginText - 1,
          ProtocolPdfConstants.marginX + marginText,
          baseY,
          1,
          listPrefix
        );
        baseY += ProtocolPdfConstants.lineHeight;
      }
      if (protocol.statement.noCorrespondToFacts) {
        baseY = this.writeText(
          this.translateService.instant('ProtocolPdf.TextStatementNoCorrespontToFacts'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.maxLineWidth - marginText - 1,
          ProtocolPdfConstants.marginX + marginText,
          baseY,
          1,
          listPrefix
        );
        baseY += ProtocolPdfConstants.lineHeight;
      }
      if (protocol.statement.agree) {
        baseY = this.writeText(
          this.translateService.instant('ProtocolPdf.TextStatementAgree'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.maxLineWidth - marginText - 1,
          ProtocolPdfConstants.marginX + marginText,
          baseY,
          1,
          listPrefix
        );
        baseY += ProtocolPdfConstants.lineHeight;
      }
      if (protocol.statement.disagree) {
        baseY = this.writeText(
          this.translateService.instant('ProtocolPdf.TextStatementDisagree'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.maxLineWidth - marginText - 1,
          ProtocolPdfConstants.marginX + marginText,
          baseY,
          1,
          listPrefix
        );
        baseY += ProtocolPdfConstants.lineHeight;
      }

      if (protocol.statement.remark) {
        baseY = this.writeMultiLineText(
          multiLineRemark,
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.marginX + marginText,
          baseY,
          multiLineRemark.length,
          listPrefix
        );
        baseY += (multiLineRemarkLines + 1) * ProtocolPdfConstants.lineHeight;
      }
    }
    baseY += this.getNewLine(1);
    return baseY;
  }

  protected addFees(fees: ProtocolFeesDto, baseY: number, useSmallPositionColumn = true): number {
    let margin2ndColumn = ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth;
    margin2ndColumn -= useSmallPositionColumn ? 22 : 0;
    const marginAnzahlExtra = 20;
    const marginAnzahl = margin2ndColumn + marginAnzahlExtra;
    const marginTarif = marginAnzahl + 25;
    const marginGebuehr = marginTarif + 20;
    const marginBemerkung = marginGebuehr + 3;
    const margingGebuerPositionX = marginGebuehr - 35;

    if (fees.feesSelection !== 0 && fees.displayFeesOnProtocol === 1) {
      const calculatedHeight = this.calculateFeesHeight(fees, marginBemerkung);
      if (baseY + calculatedHeight > ProtocolPdfConstants.maxYValue) {
        baseY = this.newPage();
      }
      this.writeText(
        this.translateService.instant('ProtocolPdf.LabelFee'),
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.boldFontType,
        ProtocolPdfConstants.firstColumnWidth - 1,
        ProtocolPdfConstants.marginX,
        baseY,
        1
      );
      const feesSelectionText = this.getFeesSelectionText(fees);
      baseY = baseY + ProtocolPdfConstants.lineHeight;
      baseY = this.writeText(
        feesSelectionText,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.maxXValue - ProtocolPdfConstants.marginX,
        ProtocolPdfConstants.marginX,
        baseY,
        0
      );
      if (fees.feesSelection !== 0) {
        baseY += 1.5 * ProtocolPdfConstants.lineHeight;
        this.writeText(
          this.translateService.instant('ProtocolPdf.LabelPosition'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.boldFontType,
          marginAnzahl - ProtocolPdfConstants.marginX - 1,
          ProtocolPdfConstants.marginX,
          baseY,
          1
        );
        this.writeText(
          this.translateService.instant('ProtocolPdf.LabelCount'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.boldFontType,
          marginTarif - marginAnzahl - 1,
          marginAnzahl - 1,
          baseY,
          1
        );
        this.writeText(
          this.translateService.instant('ProtocolPdf.LabelPrice'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.boldFontType,
          marginGebuehr - marginTarif - 1,
          marginTarif - 10,
          baseY,
          1
        );
        this.writeText(
          this.translateService.instant('ProtocolPdf.LabelTotal'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.boldFontType,
          marginBemerkung + 10 - marginGebuehr - 1,
          marginGebuehr - 10,
          baseY,
          1
        );
        baseY += ProtocolPdfConstants.lineHeight;

        if (fees.positions === undefined) {
          return;
        }

        for (const position of fees.positions) {
          if (position.selected) {
            const originalPositionY = baseY;

            const multiLinePosition = this.getMultiLineText(
              ProtocolPdfConstants.defaultFontSize,
              ProtocolPdfConstants.defaultFontType,
              this.i18nTextPipe.transform(position.text),
              marginAnzahl - ProtocolPdfConstants.marginX - 1
            );
            this.writeMultiLineText(
              multiLinePosition,
              ProtocolPdfConstants.defaultFontSize,
              ProtocolPdfConstants.defaultFontType,
              ProtocolPdfConstants.marginX,
              baseY
            );

            if (multiLinePosition.length > 1) {
              baseY += (multiLinePosition.length - 1) * ProtocolPdfConstants.lineHeight;
            }

            this.writeText(
              this.decimalPipe.transform(position.hours),
              ProtocolPdfConstants.defaultFontSize,
              ProtocolPdfConstants.defaultFontType,
              marginTarif - marginAnzahl - 1,
              marginAnzahl,
              originalPositionY,
              1
            );
            this.pdfDocument.text(
              position.rate.toFixed(2),
              marginTarif,
              originalPositionY,
              { align: 'right' },
              null
            );
            this.pdfDocument.text(
              position.hoursFee.toFixed(2),
              marginGebuehr,
              originalPositionY,
              { align: 'right' },
              null
            );
            const multiLineHoursReason = this.getMultiLineText(
              ProtocolPdfConstants.defaultFontSize,
              ProtocolPdfConstants.defaultFontType,
              position.hoursReason,
              ProtocolPdfConstants.maxXValue - marginBemerkung - 1
            );
            this.writeMultiLineText(
              multiLineHoursReason,
              ProtocolPdfConstants.defaultFontSize,
              ProtocolPdfConstants.defaultFontType,
              marginBemerkung,
              originalPositionY
            );

            if (multiLineHoursReason.length > multiLinePosition.length) {
              baseY =
                originalPositionY +
                (multiLineHoursReason.length - 1) * ProtocolPdfConstants.lineHeight;
            }
            baseY += ProtocolPdfConstants.lineHeight;
          }
        }
        baseY = this.writeText(
          this.translateService.instant('ProtocolPdf.LabelTotalChf'),
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.boldFontType,
          marginGebuehr - 1,
          margingGebuerPositionX,
          baseY,
          1
        );
        this.pdfDocument.text(
          fees.totalFee.toFixed(2),
          marginGebuehr,
          baseY,
          { align: 'right' },
          null
        );
      }
      baseY += this.getNewLine(2);
    }
    return baseY;
  }

  protected addOptionsForComment(baseY: number): number {
    const maxOptionWidth = ProtocolPdfConstants.maxLineWidth - 3;
    const multiLineTextOption1 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getOptionsForCommentText1(),
      maxOptionWidth
    );
    const multiLineTextOption2 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getOptionsForCommentText2(),
      maxOptionWidth
    );
    const totalNbLines = 1 + multiLineTextOption1.length + multiLineTextOption2.length;
    const totalHeight = totalNbLines * ProtocolPdfConstants.lineHeight;
    if (baseY + totalHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextHeaderOptionsForComment'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      '-',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      3,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY = this.writeMultiLineText(
      multiLineTextOption1,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + 3,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      '-',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      3,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY = this.writeMultiLineText(
      multiLineTextOption2,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + 3,
      baseY
    );
    return baseY;
  }

  protected getOptionsForCommentText1(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionForComment1');
  }

  protected getOptionsForCommentText2(): string {
    return this.translateService.instant('ProtocolPdf.TextOptionForComment2');
  }

  protected addInspectionResultsCorrections(
    inspection: InspectionWithResultsDto,
    protocol: ProtocolDto,
    defectsMap: Map<string, Map<string, ProtocolPdfResultDto[]>>
  ): void {
    let baseY = this.newPage();
    const defectTableParams = this.getDefectTableParams();
    if (defectTableParams.defectCorrectionOnOddNumberedPage && this.nbPages % 2 === 0) {
      baseY = this.newPage();
    }
    baseY = this.getDefectCorrectionAddress(baseY);
    const firstDefectCorrectionPage = this.nbPages;
    if (defectTableParams.addControlledArea) {
      const controlledAreaMultiLineText = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.boldFontType,
        this.translateService.instant('ProtocolPdf.LabelControlledArea') +
          ' ' +
          protocol.controlledArea,
        ProtocolPdfConstants.maxLineWidth
      );
      baseY = this.writeMultiLineText(
        controlledAreaMultiLineText,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.boldFontType,
        ProtocolPdfConstants.marginX,
        baseY,
        0
      );
      baseY += 2 * ProtocolPdfConstants.lineHeight;
    }

    this.writeText(
      this.translateService.instant('ProtocolPdf.HeaderConfirmationDefectCorrection'),
      defectTableParams.defectCorrectionTitleFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    if (defectTableParams.confirmationDefectCorrectionWithDate) {
      const inspectionDate = this.datePipe.transform(
        inspection.inspectionDate,
        ProtocolPdfConstants.dateFormat
      );
      baseY = this.writeText(
        this.translateService.instant('ProtocolPdf.TextConfirmationDefectCorrectionWithDate', {
          inspectionDate,
        }),
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.maxLineWidth,
        ProtocolPdfConstants.marginX,
        baseY
      );
    } else {
      baseY = this.writeText(
        this.translateService.instant('ProtocolPdf.TextConfirmationDefectCorrection'),
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.maxLineWidth,
        ProtocolPdfConstants.marginX,
        baseY
      );
    }
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    const inspectionResultForCategoryDto = new InspectionResultForCategoryDto();
    inspectionResultForCategoryDto.defectsMap = defectsMap;
    inspectionResultForCategoryDto.defectTableParams = defectTableParams;
    inspectionResultForCategoryDto.isDefectCorrectionTable = true;
    baseY = this.addInspectionResultForCategory(inspectionResultForCategoryDto, baseY);
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.addDefectCorrectionRemarks(baseY, defectTableParams);
    this.addDefectCorrectionSignatureBlock(defectTableParams);
    this.addDefectCorrectionHeader(inspection, firstDefectCorrectionPage);
  }

  protected addImage(
    imageData: string,
    format: string,
    x: number,
    y: number,
    width: number,
    height: number
  ): void {
    try {
      if (imageData === null || typeof imageData === undefined) {
        console.log(
          this.translateService.instant(
            'Fehler beim Laden des Bildes. Bild ist leer oder Null, pdf wird ohne Bild erzeugt'
          )
        );
        return;
      }
      this.pdfDocument.addImage(imageData, format, x, y, width, height);
    } catch (error) {
      console.log(
        this.translateService.instant(
          `Fehler beim Laden des Bildes, pdf wird ohne Bild erzeugt: ${error}`
        )
      );
    }
  }

  protected getImageData(imageName: string): string {
    return this.pdfImages.images.find((image) => image.name === imageName).imageData;
  }

  protected addFooter(inspection: InspectionWithResultsDto): void {
    this.pdfDocument.setFontSize(ProtocolPdfConstants.smallFontSize);
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    for (let i = 1; i <= this.nbPages; i++) {
      this.pdfDocument.setPage(i);
      let y = ProtocolPdfConstants.footerLineYValue;
      this.pdfDocument.line(ProtocolPdfConstants.marginX, y, ProtocolPdfConstants.maxXValue, y);
      const pageText = this.translateService.instant('ProtocolPdf.PageNumber', {
        page: i,
        total: this.nbPages,
      });

      y += 0.75 * ProtocolPdfConstants.lineHeight;
      let leftText =
        (inspection.inspectorName ? inspection.inspectorName + ', ' : '') +
        this.datePipe.transform(inspection.inspectionDate, ProtocolPdfConstants.dateFormat);
      if (this.getDefectTableParams().showUnitNameOnFooter) {
        leftText = inspection.unitName;
      }
      const labelInspectionId = this.translateService.instant('ProtocolPdf.LabelInspectionID');
      const labelInspectionAsanId = this.translateService.instant(
        'ProtocolPdf.LabelInspectionAsanID'
      );
      const inspectionId = inspection.inspectionId ? inspection.inspectionId.toString() : '';
      const caseId = inspection.caseId
        ? `/ ${labelInspectionAsanId} ${inspection.caseId.toString()}`
        : '';
      const labelIds = `${labelInspectionId} ${inspectionId} ${caseId}`;
      this.pdfDocument.text(leftText + ', ' + labelIds, ProtocolPdfConstants.marginX, y);
      this.pdfDocument.text(pageText, ProtocolPdfConstants.maxXValue, y, { align: 'right' });
    }
  }

  protected writeText(
    text: string,
    size: number,
    type: string,
    maxTextWidth: number,
    x: number,
    y: number,
    maxNbRows?: number,
    prefix?: string,
    multiLinesPrefix?: string
  ): number {
    if (!text) {
      text = '';
    }

    const multiLineText = this.getMultiLineText(size, type, text, maxTextWidth);
    return this.writeMultiLineText(
      multiLineText,
      size,
      type,
      x,
      y,
      maxNbRows,
      prefix,
      multiLinesPrefix
    );
  }

  protected writeMultiLineText(
    multiLineText: string[],
    fontSize: number,
    fontType: string,
    x: number,
    y: number,
    maxNbRows?: number,
    prefix?: string,
    multiLinesPrefix?: string
  ): number {
    this.setFontType(fontType);
    this.pdfDocument.setFontSize(fontSize);

    const nbRowsNeeded = multiLineText.length;
    let nbRows = multiLineText.length;
    if (maxNbRows > 0) {
      nbRows = Math.min(nbRows, maxNbRows);
    }
    if (y + nbRows * ProtocolPdfConstants.lineHeight > ProtocolPdfConstants.maxYValue) {
      y = this.newPage();
    }

    for (let i = 0; i < nbRows; i++) {
      let text = multiLineText[i];
      if (nbRowsNeeded > nbRows && i === nbRows - 1) {
        text = text.substring(0, text.length - 4) + ' ...';
      }

      if (i === 0) {
        text = prefix !== undefined && prefix !== null ? `${prefix} ${text}` : text;
      } else {
        text =
          multiLinesPrefix !== undefined && multiLinesPrefix !== null
            ? `${multiLinesPrefix} ${text}`
            : text;
      }

      this.pdfDocument.text(text, x, y);
      if (i < multiLineText.length - 1) {
        y += ProtocolPdfConstants.lineHeight;
        if (y > ProtocolPdfConstants.maxYValue) {
          y = this.newPage();
        }
      }
    }

    return y;
  }

  protected newPage(): number {
    this.pdfDocument.addPage();
    this.nbPages++;
    return ProtocolPdfConstants.marginYFurtherPages;
  }

  protected addFieldValues(
    fieldValues: Map<string, Map<string, ProtocolPdfResultDto[]>>,
    baseY: number
  ): number {
    if (!fieldValues.size) {
      return baseY;
    }
    baseY += ProtocolPdfConstants.lineHeight;
    const sortedFieldValuesPerPath: ProtocolPdfResultDto[] = [];
    fieldValues.forEach((fieldValuePerCategory: Map<string, ProtocolPdfResultDto[]>) => {
      const sortedPaths = this.getSortedResultPaths(fieldValuePerCategory);
      sortedPaths.forEach((path) => {
        const fieldValuesPerPath = fieldValuePerCategory.get(path);
        fieldValuesPerPath.forEach((element) => {
          sortedFieldValuesPerPath.push(element);
        });
      });
    });
    let calculatedHeight = ProtocolPdfConstants.lineHeight;
    calculatedHeight += this.calculateFieldValueTableHeight(sortedFieldValuesPerPath);
    if (baseY + calculatedHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    baseY = this.writeText(
      this.translateService.instant('ProtocolPdf.FieldValues') + ':',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxXValue - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;

    baseY = this.addFieldValueTable(sortedFieldValuesPerPath, baseY);
    return baseY;
  }

  protected getMultiLineNumber(text: string, maxWidth?: number): number {
    if (maxWidth === undefined) {
      maxWidth = ProtocolPdfConstants.maxLineWidth - ProtocolPdfConstants.firstColumnWidth;
    }
    const lines = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      text,
      maxWidth
    );
    return lines.length;
  }

  protected getMultiLineText(
    fontSize: number,
    fontType: string,
    text: string,
    maxWidth: number
  ): string[] {
    this.pdfDocument.setFontSize(fontSize);
    this.setFontType(fontType);
    return text ? this.pdfDocument.splitTextToSize(text, maxWidth) : [];
  }

  protected writeDottedLine(
    fontSize: number,
    fontType: string,
    maxWidth: number,
    x: number,
    y: number
  ): number {
    const character = '.';
    return this.writeLineWithSpecialCharacter(fontSize, fontType, maxWidth, x, y, character);
  }

  protected writeLine(
    fontSize: number,
    fontType: string,
    maxWidth: number,
    x: number,
    y: number
  ): number {
    const character = '_';
    return this.writeLineWithSpecialCharacter(fontSize, fontType, maxWidth, x, y, character);
  }

  protected setFontType(fontStyle: string, fontName?: string): void {
    if (fontName === undefined) {
      fontName = ProtocolPdfConstants.fontType;
    }
    this.pdfDocument.setFont(fontName, fontStyle);
  }

  protected validateHasControlledLocationValue(): boolean {
    return false;
  }

  protected getDefectCorrectionAddress(baseY: number): number {
    return baseY;
  }

  protected getInspectionHeaderSecondColumnTexts(
    inspection: InspectionWithResultsDto,
    extendedVersion: boolean,
    protocol: ProtocolDto
  ): Map<string, string> {
    const columnTexts = new Map<string, string>();
    columnTexts.set(this.translateService.instant('ProtocolPdf.LabelTVDNr'), inspection.unitTvdId);
    columnTexts.set(this.translateService.instant('ProtocolPdf.LabelBURNr'), inspection.unitBurId);
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelKTID'),
      inspection.unitCantonId
    );
    if (extendedVersion) {
      columnTexts.set(
        this.translateService.instant('ProtocolPdf.LabelPhone'),
        this.getInspectionHeaderPhone(inspection)
      );
    }
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      this.getInspectionDate(inspection)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelDuration'),
      this.getInspectionDuration(protocol)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelType'),
      this.getInspectionStyleName(inspection)
    );
    return columnTexts;
  }

  protected getInspectionDate(inspection: InspectionWithResultsDto): string {
    return this.datePipe.transform(inspection.inspectionDate, ProtocolPdfConstants.dateFormat);
  }

  protected getInspectionStyleName(inspection: InspectionWithResultsDto) {
    let inspectionStyleName = '';
    if (inspection.inspectionStyle && inspection.inspectionStyle.name) {
      inspectionStyleName = this.i18nTextPipe.transform(inspection.inspectionStyle.name);
    }
    return inspectionStyleName;
  }

  protected getInspectionDuration(protocol: ProtocolDto) {
    return (
      (protocol.inspectionStartDate
        ? protocol.inspectionStartDate.hour +
          ':' +
          (protocol.inspectionStartDate.minute < 10
            ? '0' + protocol.inspectionStartDate.minute
            : protocol.inspectionStartDate.minute)
        : '???') +
      ' - ' +
      (protocol.inspectionEndDate
        ? protocol.inspectionEndDate.hour +
          ':' +
          (protocol.inspectionEndDate.minute < 10
            ? '0' + protocol.inspectionEndDate.minute
            : protocol.inspectionEndDate.minute)
        : '???')
    );
  }

  protected getDefectTableHeaderMeasure(): string {
    return this.translateService.instant('ProtocolPdf.DefectTableHeaderMeasure');
  }

  protected addInspectionResultsWithoutDefect(protocolPdf: ProtocolPdfDto, baseY: number): number {
    const categories = this.getCategoriesWithoutDefect(protocolPdf);
    if (categories.length > 0) {
      protocolPdf.hasInspectionResultsWithoutDefect = true;
      baseY = this.addInspectionResultsWithoutDefectTitle(baseY, 'Kontrollergebnis');
      baseY = this.addInspectionResultsCategoriesResultTitle(
        baseY,
        this.i18nTextPipe.transform(protocolPdf.titleControlledCategories)
      );

      categories.forEach((category: ProtocolPdfCategoryWithoutDefectDto) => {
        const categoryText = this.getMultiLineCategoryWithoutDefectText(category);
        baseY =
          this.writeMultiLineText(
            categoryText,
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            ProtocolPdfConstants.marginX,
            baseY
          ) + ProtocolPdfConstants.lineHeight;
      });
      baseY += 10;
    }
    return baseY;
  }

  protected addNkCategoryResultsDisplayedInProtocol(
    protocolPdf: ProtocolPdfDto,
    baseY: number
  ): number {
    const categories = this.getNkCategoryResultsDisplayedInProtocol(protocolPdf);
    const subtitleCategories = this.translateService.instant('ProtocolPdf.NotControlledAreas');
    if (categories.length > 0) {
      baseY += ProtocolPdfConstants.lineHeight;

      const titleHeight = 2 + ProtocolPdfConstants.lineHeight;
      if (
        baseY + titleHeight + categories.length * ProtocolPdfConstants.lineHeight >
        ProtocolPdfConstants.maxYValue
      ) {
        baseY = this.newPage();
      }
      baseY = this.addInspectionResultsCategoriesResultTitle(baseY, subtitleCategories);

      categories.forEach((category: ProtocolPdfCategoryWithoutDefectDto) => {
        const categoryText = this.getMultiLineCategoryWithoutDefectText(category);
        baseY =
          this.writeMultiLineText(
            categoryText,
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            ProtocolPdfConstants.marginX,
            baseY
          ) + ProtocolPdfConstants.lineHeight;
      });
      baseY += 10;
    }
    return baseY;
  }

  protected getInspectionHeaderEmail(inspection: InspectionWithResultsDto): string {
    return inspection.unitEmail;
  }

  protected getInspectionHeaderUnitName(inspection: InspectionWithResultsDto) {
    return inspection.unitName;
  }

  protected getInspectionHeaderAddress(inspection: InspectionWithResultsDto) {
    return (
      (inspection.unitStreetName ? inspection.unitStreetName : '') +
      (inspection.unitStreetNumber ? ' ' + inspection.unitStreetNumber : '')
    );
  }

  protected getInspectionHeaderPostalZoneTown(inspection: InspectionWithResultsDto) {
    return (
      (inspection.unitPostalZone ? inspection.unitPostalZone + ' ' : '') +
      (inspection.unitTown ? inspection.unitTown : '')
    );
  }

  protected getInspectionHeaderPhone(inspection: InspectionWithResultsDto) {
    return inspection.unitWorkPhones;
  }

  protected addFurtherProcedureDetails(baseY: number, withBulletPoints: boolean = true): number {
    const maxDetailWidth = ProtocolPdfConstants.maxLineWidth - 3;
    const multiLineTextDetail1 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getTextOptionFurtherProcedureDetails1(),
      maxDetailWidth
    );
    const multiLineTextDetail2 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getTextOptionFurtherProcedureDetails2(),
      maxDetailWidth
    );
    const multiLineTextDetail3 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getTextOptionFurtherProcedureDetails3(),
      maxDetailWidth
    );
    const multiLineTextDetail4 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getTextOptionFurtherProcedureDetails4(),
      maxDetailWidth
    );
    const multiLineTextDetail5 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getTextOptionFurtherProcedureDetails5(),
      maxDetailWidth
    );
    const totalNbLines =
      1 +
      multiLineTextDetail1.length +
      multiLineTextDetail2.length +
      multiLineTextDetail3.length +
      multiLineTextDetail4.length +
      multiLineTextDetail5.length;
    const totalHeight =
      totalNbLines * ProtocolPdfConstants.lineHeight + 3 * ProtocolPdfConstants.lineHeight;
    if (baseY + totalHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.getTextHeaderFurtherProcedureDetails(),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      withBulletPoints ? '-' : '',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      3,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY = this.writeMultiLineText(
      multiLineTextDetail1,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + 3,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      withBulletPoints ? '-' : '',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      3,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY = this.writeMultiLineText(
      multiLineTextDetail2,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + 3,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      withBulletPoints ? '-' : '',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      3,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY = this.writeMultiLineText(
      multiLineTextDetail3,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX + 3,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    if (multiLineTextDetail4.length !== 0) {
      this.writeText(
        withBulletPoints ? '-' : '',
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        3,
        ProtocolPdfConstants.marginX,
        baseY,
        1
      );
      baseY = this.writeMultiLineText(
        multiLineTextDetail4,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.marginX + 3,
        baseY
      );
      baseY += ProtocolPdfConstants.lineHeight;
    }
    if (multiLineTextDetail5.length !== 0) {
      this.writeText(
        withBulletPoints ? '-' : '',
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        3,
        ProtocolPdfConstants.marginX,
        baseY,
        1
      );
      baseY = this.writeMultiLineText(
        multiLineTextDetail5,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.marginX + 3,
        baseY
      );
      baseY += ProtocolPdfConstants.lineHeight;
    }
    return baseY;
  }

  protected getTextHeaderFurtherProcedureDetails(): string {
    return '';
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return '';
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return '';
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return '';
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return '';
  }

  protected getTextOptionFurtherProcedureDetails5(): string {
    return '';
  }

  protected addInspectionResultsDisplayedOnTop(
    protocolPdfDto: ProtocolPdfDto,
    baseY: number
  ): number {
    const inspectionResultsDisplayedOnTop =
      this.inspectionResultsService.getInspectionResultsByAccessor({
        ...protocolPdfDto,
        inspectionResultAccessor: InspectionResultAccessor.fieldValueWithOnTopOfProtocolPdf,
      });
    if (inspectionResultsDisplayedOnTop && inspectionResultsDisplayedOnTop.size > 0) {
      baseY += ProtocolPdfConstants.lineHeight / 2;
      const inspectionResultForCategoryDto = this.createInspectionResultForCategory(
        inspectionResultsDisplayedOnTop,
        this.getDefectTableParams()
      );
      inspectionResultForCategoryDto.displayInspectionDataOnTopOfProtocolPdf = true;
      baseY = this.addInspectionResultForCategory(inspectionResultForCategoryDto, baseY);
    }
    return baseY;
  }

  protected getPointNumber(defect: ProtocolPdfResultDto): string {
    return defect.pointNumber;
  }

  private calculateDefectsTableHeightWithPath(
    defects: ProtocolPdfResultDto[],
    includeCorrectedColumn: boolean,
    defectTableParams: DefectTableParams
  ): number {
    let totalHeight = 0;
    const tableHeaderHeight = 2 * ProtocolPdfConstants.lineHeight;
    this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    const multiLineCategoryText = this.getMultiLineCategoryText(defects[0]);
    totalHeight += multiLineCategoryText.length * ProtocolPdfConstants.lineHeight;
    const multiLinePointGroupNameText = this.getMultiLinePointGroupText(defects[0]);
    totalHeight += multiLinePointGroupNameText.length * ProtocolPdfConstants.lineHeight;
    totalHeight += tableHeaderHeight;
    if (includeCorrectedColumn) {
      totalHeight += this.calculateDefectCorrectionTableHeight(defects[0], defectTableParams);
    } else {
      totalHeight += this.calculateDefectTableHeight(defects[0], defectTableParams);
    }
    return totalHeight;
  }

  private addFieldValueTableForCategory(
    fieldValuesPerPath: ProtocolPdfResultDto[],
    baseY: number,
    inspectionResultForCategoryDto: InspectionResultForCategoryDto
  ): number {
    const multilineCategoryText = this.addDefectFieldValuePathText(
      fieldValuesPerPath,
      inspectionResultForCategoryDto.displayInspectionDataOnTopOfProtocolPdf,
      inspectionResultForCategoryDto.isFirstPathForCategory
    );
    baseY = this.addDefectsFieldValueTable(fieldValuesPerPath, multilineCategoryText, baseY);
    return baseY;
  }

  private getSortedResultPaths(defectsPerCategory: Map<string, ProtocolPdfResultDto[]>): string[] {
    const pathsList = Array.from(defectsPerCategory.keys());
    return _.sortBy(pathsList, (path) => {
      let sortString = '';
      const defectsForPath = defectsPerCategory.get(path);
      if (defectsForPath.length === 1) {
        if (defectsForPath[0] instanceof ProtocolPdfAggregatedResultDto) {
          sortString += '0.' + defectsForPath[0].pointNumber;
        } else {
          sortString +=
            '1.' +
            defectsForPath[0].pointGroupNames.forEach((pointGroupName) =>
              this.i18nTextPipe.transform(pointGroupName)
            );
        }
      } else {
        sortString +=
          '1.' +
          defectsForPath[0].pointGroupNames.forEach((pointGroupName) =>
            this.i18nTextPipe.transform(pointGroupName)
          );
      }
    });
  }

  private addDefectsWithColumnDefectTableHeader(columns: Map<string, number>, baseY: number) {
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderNr'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthNumber,
      columns.get('Number'),
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderDefect'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthDefect,
      columns.get('Defect'),
      baseY,
      1
    );
    this.writeText(
      this.getDefectTableHeaderMeasure(),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthMeasure,
      columns.get('Measure'),
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderDeadline'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthDeadline,
      columns.get('Deadline'),
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight / 2;
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
    return (baseY += ProtocolPdfConstants.lineHeight);
  }

  private addInspectionResultsTitle(
    protocolPdf: ProtocolPdfDto,
    hasDefects: boolean,
    baseY: number,
    defectTableParams: DefectTableParams
  ): number {
    if (!protocolPdf.hasInspectionResultsWithoutDefect) {
      if (!hasDefects) {
        this.pdfDocument.text(
          this.translateService.instant('ProtocolPdf.TextNoDefects'),
          ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth,
          baseY
        );
      } else {
        if (defectTableParams.includeHeaderDefect) {
          this.pdfDocument.text(
            this.translateService.instant('ProtocolPdf.TextDefectsAccordingToList'),
            ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth,
            baseY
          );
        } else {
          this.pdfDocument.text(
            this.translateService.instant('ProtocolPdf.TextMeasureAccordingToList'),
            ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth,
            baseY
          );
        }
      }
      baseY += 2;
      this.pdfDocument.setLineWidth(0.3);
      this.pdfDocument.line(
        ProtocolPdfConstants.marginX,
        baseY,
        ProtocolPdfConstants.maxXValue,
        baseY
      );
      if (!hasDefects) {
        return (baseY += ProtocolPdfConstants.lineHeight * 4);
      }
      return (baseY += ProtocolPdfConstants.lineHeight);
    } else {
      if (!hasDefects) {
        return baseY;
      } else {
        this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
        this.setFontType(ProtocolPdfConstants.defaultFontType);
        this.pdfDocument.text(
          this.translateService.instant('ProtocolPdf.TextControlledCategoriesWithDefect'),
          ProtocolPdfConstants.marginX,
          baseY
        );
        baseY += 2;
        this.pdfDocument.setLineWidth(0.3);
        this.pdfDocument.line(
          ProtocolPdfConstants.marginX,
          baseY,
          ProtocolPdfConstants.maxXValue,
          baseY
        );
        return (baseY += ProtocolPdfConstants.lineHeight);
      }
    }
  }

  private addInspectionResultLabel(protocolPdf: ProtocolPdfDto, baseY: number): void {
    if (!protocolPdf.hasInspectionResultsWithoutDefect) {
      this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
      this.setFontType(ProtocolPdfConstants.boldFontType);
      this.pdfDocument.text(
        this.translateService.instant('ProtocolPdf.LabelInspectionResult'),
        ProtocolPdfConstants.marginX,
        baseY
      );
    }
  }

  private addInspectionHeaderFirstColumn(
    inspection: InspectionWithResultsDto,
    extendedVersion: boolean,
    protocol: ProtocolDto,
    margin2ndColumn: number,
    margin3rdColumn: number,
    baseY: number
  ) {
    const firstColumnTexts = this.getInspectionHeaderFirstColumnTexts(
      inspection,
      extendedVersion,
      protocol
    );

    const firstColumnLabelMaxTextWidth = margin2ndColumn - ProtocolPdfConstants.marginX - 1;
    const firstColumnLabelPositionX = ProtocolPdfConstants.marginX;

    const firstColumnTextMaxTextWidth = margin3rdColumn - margin2ndColumn - 1;
    const firstColumnTextPositionX = margin2ndColumn;

    baseY = this.writeMultiTextWithLabels(
      firstColumnTexts,
      baseY,
      firstColumnLabelMaxTextWidth,
      firstColumnLabelPositionX,
      firstColumnTextMaxTextWidth,
      firstColumnTextPositionX
    );

    return baseY;
  }

  private getInspectionHeaderFirstColumnTexts(
    inspection: InspectionWithResultsDto,
    extendedVersion: boolean,
    protocol: ProtocolDto
  ): Map<string, string> {
    const columnTexts = new Map<string, string>();
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelName'),
      this.getInspectionHeaderUnitName(inspection)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelAddress'),
      this.getInspectionHeaderAddress(inspection)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelPostalZoneTown'),
      this.getInspectionHeaderPostalZoneTown(inspection)
    );
    this.addInspectionHeaderUnitEmail(extendedVersion, columnTexts, inspection);
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelReason'),
      this.i18nTextPipe.transform(inspection.inspectionReasonName)
    );
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelControlledArea'),
      protocol.controlledArea
    );
    this.addInspectionHeaderControlledLocation(columnTexts, protocol);
    return columnTexts;
  }

  private addInspectionHeaderControlledLocation(
    columnTexts: Map<string, string>,
    protocol: ProtocolDto
  ): void {
    if (this.validateHasControlledLocationValue() && protocol.controlledLocation == null) {
      return;
    }
    columnTexts.set(
      this.translateService.instant('ProtocolPdf.LabelControlledLocation'),
      protocol.controlledLocation
    );
  }

  private addInspectionHeaderUnitEmail(
    extendedVersion: boolean,
    columnTexts: Map<string, string>,
    inspection: InspectionWithResultsDto
  ) {
    if (extendedVersion) {
      columnTexts.set(
        this.translateService.instant('ProtocolPdf.LabelEmailD'),
        this.getInspectionHeaderEmail(inspection)
      );
    }
  }

  private addInspectionHeaderSecondColumn(
    inspection: InspectionWithResultsDto,
    extendedVersion: boolean,
    protocol: ProtocolDto,
    margin3rdColumn: number,
    margin4thColumn: number,
    baseY: number
  ) {
    const secondColumnTexts = this.getInspectionHeaderSecondColumnTexts(
      inspection,
      extendedVersion,
      protocol
    );

    const secondColumnLabelMaxTextWidth = margin4thColumn - margin3rdColumn - 1;
    const secondColumnLabelPositionX = margin3rdColumn;
    const secondColumnTextMaxTextWidth = ProtocolPdfConstants.maxXValue - margin4thColumn;
    const secondColumnTextPositionX = margin4thColumn;

    baseY = this.writeMultiTextWithLabels(
      secondColumnTexts,
      baseY,
      secondColumnLabelMaxTextWidth,
      secondColumnLabelPositionX,
      secondColumnTextMaxTextWidth,
      secondColumnTextPositionX
    );
    return baseY;
  }

  private getParagraphTotalMultilines(paragraph: PdfDocumentParagraph): number {
    let totalLines = 0;
    if (paragraph.headerText !== null) {
      totalLines += 1;
    }
    paragraph.paragraphTexts.forEach((text) => {
      const paragraphText =
        paragraph.prefix !== undefined && paragraph.prefix !== null
          ? `${paragraph.prefix} ${text}`
          : text;
      totalLines += this.getMultiLineNumber(paragraphText);
    });
    return totalLines;
  }

  private addInspectionResultForCategory(
    inspectionResultForCategoryDto: InspectionResultForCategoryDto,
    baseY: number
  ): number {
    inspectionResultForCategoryDto.defectsMap.forEach(
      (resultsPerCategory: Map<string, ProtocolPdfResultDto[]>) => {
        baseY = this.addResultsForCategory(
          inspectionResultForCategoryDto,
          resultsPerCategory,
          baseY
        );
      }
    );
    return baseY;
  }

  private addResultsForCategory(
    inspectionResultForCategoryDto: InspectionResultForCategoryDto,
    resultsPerCategory: Map<string, ProtocolPdfResultDto[]>,
    baseY: number
  ): number {
    inspectionResultForCategoryDto.isFirstPathForCategory = true;
    const sortedPaths = this.getSortedResultPaths(resultsPerCategory);
    const hasDefectsOnCategory = this.hasDefectsOnCategory(resultsPerCategory);
    if (!hasDefectsOnCategory) {
      baseY += ProtocolPdfConstants.lineHeight;
    }
    sortedPaths.forEach((path) => {
      const results = resultsPerCategory.get(path);
      baseY = this.addDefectAndFieldValueTableForCategory(
        results,
        hasDefectsOnCategory,
        inspectionResultForCategoryDto,
        baseY
      );
    });
    return baseY;
  }

  private addDefectAndFieldValueTableForCategory(
    results: ProtocolPdfResultDto[],
    hasDefectsOnCategory: boolean,
    inspectionResultForCategoryDto: InspectionResultForCategoryDto,
    baseY: number
  ): number {
    if (inspectionResultForCategoryDto.isFirstPathForCategory && hasDefectsOnCategory) {
      baseY = this.addCategoryTitle(results, inspectionResultForCategoryDto, baseY);
      inspectionResultForCategoryDto.isFirstPathForCategory = false;
    }
    if (results.some((a) => a.inspectionResult.inspectionResult === InspectionResultType.Defect)) {
      const defectsPerPath = results.filter(
        (a) => a.inspectionResult.inspectionResult === InspectionResultType.Defect
      );
      const calculatedHeight = this.calculateDefectsTableHeightWithPath(
        defectsPerPath,
        inspectionResultForCategoryDto.isDefectCorrectionTable,
        inspectionResultForCategoryDto.defectTableParams
      );
      if (baseY + calculatedHeight > ProtocolPdfConstants.maxYValue) {
        baseY = this.newPage();
      }
      const defectsPerPathGroupByGroupName = this.groupBy(defectsPerPath, (defect) =>
        defect.pointGroupNames[0] ? this.i18nTextPipe.transform(defect.pointGroupNames[0]) : ''
      );
      defectsPerPathGroupByGroupName.forEach((defectPerGroupName: ProtocolPdfResultDto[]) => {
        if (inspectionResultForCategoryDto.isDefectCorrectionTable) {
          baseY = this.addDefectsCorrectionTable(
            defectPerGroupName,
            baseY,
            inspectionResultForCategoryDto.defectTableParams
          );
        } else {
          if (inspectionResultForCategoryDto.defectTableParams.includeHeaderDefect) {
            baseY = this.addDefectsWithColumnDefectTable(
              defectPerGroupName,
              baseY,
              inspectionResultForCategoryDto.defectTableParams.extraMarginColumnDeadline
            );
          } else {
            baseY = this.addDefectsTable(defectPerGroupName, baseY);
          }
        }
      });
    }

    const showFieldValues = !inspectionResultForCategoryDto.isDefectCorrectionTable;
    const hasFieldValues = results.some(
      (a) => a.inspectionResult.inspectionResult !== InspectionResultType.Defect
    );
    if (showFieldValues && hasFieldValues) {
      const fieldValuesPerPath = results.filter(
        (a) => a.inspectionResult.inspectionResult !== InspectionResultType.Defect
      );
      baseY = this.addFieldValueTableForCategory(
        fieldValuesPerPath,
        baseY,
        inspectionResultForCategoryDto
      );
    }
    baseY += 0.5 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  private groupBy(list: any[], keyGetter: any): Map<any, any[]> {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  private addDefectPointGroupTitle(defectsPerPath: ProtocolPdfResultDto[], baseY: number): number {
    const multiLinePointGroupText = this.getMultiLinePointGroupText(defectsPerPath[0]);
    baseY =
      this.writeMultiLineText(
        multiLinePointGroupText,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.marginX,
        baseY,
        0
      ) +
      ProtocolPdfConstants.lineHeight * 0.5;
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  private addCategoryTitle(
    results: ProtocolPdfResultDto[],
    inspectionResultForCategoryDto: InspectionResultForCategoryDto,
    baseY: number
  ): number {
    if (!inspectionResultForCategoryDto.isDefectCorrectionTable) {
      baseY += ProtocolPdfConstants.lineHeight - 0.8;
    } else {
      baseY += ProtocolPdfConstants.lineHeight / 2;
    }
    const multiLineCategoryText = this.getMultiLineCategoryText(results[0]);
    const calculatedHeight =
      this.calculateDefectsTableHeightWithPath(
        results,
        inspectionResultForCategoryDto.isDefectCorrectionTable,
        inspectionResultForCategoryDto.defectTableParams
      ) +
      ProtocolPdfConstants.lineHeight * (multiLineCategoryText.length + 1);
    if (baseY + calculatedHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    const fontTypeRubrics = this.getDefectTableParams().defectTableRubricsFontType;
    baseY =
      this.writeMultiLineText(
        multiLineCategoryText,
        ProtocolPdfConstants.defaultFontSize,
        fontTypeRubrics,
        ProtocolPdfConstants.marginX,
        baseY
      ) +
      1.5 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  private addDefectsFieldValueTable(
    fieldValuesPerPath: ProtocolPdfResultDto[],
    multiLineHeaderText: string[],
    baseY: number
  ): number {
    if (fieldValuesPerPath && fieldValuesPerPath.length !== 0) {
      const calculatedHeight = this.calculateFieldValueTableHeight(
        fieldValuesPerPath,
        multiLineHeaderText
      );
      if (baseY + calculatedHeight > ProtocolPdfConstants.maxYValue) {
        baseY = this.newPage();
      }
      baseY = this.addFieldValueTable(fieldValuesPerPath, baseY, multiLineHeaderText);
    }
    return baseY;
  }

  private addDefectFieldValuePathText(
    fieldValuesPerPath: ProtocolPdfResultDto[],
    displayInspectionDataOnTopOfProtocolPdf: boolean,
    showCategoryName: boolean
  ): string[] {
    let categoryText = '';
    let pointGroupText = '';
    if (showCategoryName) {
      categoryText = this.i18nTextPipe.transform(fieldValuesPerPath[0].categoryName) + ' / ';
    }
    pointGroupText = fieldValuesPerPath[0].pointGroupNames[0]
      ? this.i18nTextPipe.transform(fieldValuesPerPath[0].pointGroupNames[0]) + ' / '
      : '';
    const headerText = this.translateService.instant('ProtocolPdf.LabelBetriebZusaetzlicheAngaben');
    let title = `${categoryText}${pointGroupText}${headerText}`;
    if (displayInspectionDataOnTopOfProtocolPdf) {
      const categoryName = this.i18nTextPipe.transform(fieldValuesPerPath[0].categoryName);
      title = `${this.translateService.instant(
        'ProtocolPdf.LabelBetriebBasisdaten'
      )} ${categoryName}`;
    }
    const multilineCategoryText = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      title,
      ProtocolPdfConstants.maxLineWidth
    );
    return multilineCategoryText;
  }

  private addDefectsWithColumnDefectTable(
    defects: ProtocolPdfResultDto[],
    baseY: number,
    extraMarginColumnDeadline: number = 0
  ): number {
    baseY = this.addDefectPointGroupTitle(defects, baseY);
    const columns = this.getDefectsWithColumnDefectTableColumns(extraMarginColumnDeadline);
    baseY = this.addDefectsWithColumnDefectTableHeader(columns, baseY);
    let maxLineHeightDefect = 0;
    defects.forEach((defect) => {
      maxLineHeightDefect = this.getMaxLineHeightFromOriginalDefect(defect);
      baseY = this.addDefectWithColumnDefectToTable(defect, columns, baseY, maxLineHeightDefect);
    });
    return baseY;
  }

  private getDefectsWithColumnDefectTableColumns(extraMarginColumnDeadline: number) {
    const columns = new Map<string, number>();
    columns.set('Number', ProtocolPdfConstants.marginX);
    columns.set(
      'Defect',
      columns.get('Number') + ProtocolPdfConstants.defectsTableColumnWidthNumber
    );
    columns.set(
      'Measure',
      columns.get('Defect') + ProtocolPdfConstants.defectsTableColumnWidthDefect
    );
    columns.set(
      'Deadline',
      columns.get('Measure') +
        ProtocolPdfConstants.defectsTableColumnWidthMeasure +
        extraMarginColumnDeadline
    );
    return columns;
  }

  private getMaxLineHeightFromOriginalDefect(
    defect: ProtocolPdfResultDto,
    maxLineHeight: number = 0
  ) {
    const deadlineText = defect.inspectionResult.complaintRemedyDateDue
      ? this.datePipe.transform(
          defect.inspectionResult.complaintRemedyDateDue,
          ProtocolPdfConstants.dateFormat
        )
      : '';
    const multiLineTextPointNumber = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.getPointNumber(defect),
      ProtocolPdfConstants.defectsTableColumnWidthNumber - 1
    );
    const multiLineTextDescription = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      defect.inspectionResult.defectDescription,
      ProtocolPdfConstants.defectsTableColumnWidthDefect - 3
    );
    const multiLineTextRemark = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      defect.inspectionResult.remark,
      ProtocolPdfConstants.defectsTableColumnWidthMeasure - 2
    );
    const multiLineTextDeadline = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      deadlineText,
      ProtocolPdfConstants.defectsTableColumnWidthDeadline - 1
    );
    maxLineHeight =
      Math.max(
        multiLineTextPointNumber.length,
        multiLineTextDescription.length,
        multiLineTextRemark.length,
        multiLineTextDeadline.length
      ) * ProtocolPdfConstants.lineHeight;
    return maxLineHeight;
  }

  private addDefectWithColumnDefectToTable(
    defect: ProtocolPdfResultDto,
    columns: Map<string, number>,
    baseY: number,
    maxLineHeight: number
  ) {
    const deadlineText = defect.inspectionResult.complaintRemedyDateDue
      ? this.datePipe.transform(
          defect.inspectionResult.complaintRemedyDateDue,
          ProtocolPdfConstants.dateFormat
        )
      : '';
    if (baseY + maxLineHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.getPointNumber(defect),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsTableColumnWidthNumber - 1,
      columns.get('Number'),
      baseY,
      0
    );
    this.writeText(
      defect.inspectionResult.defectDescription,
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsTableColumnWidthDefect - 3,
      columns.get('Defect'),
      baseY,
      0
    );
    this.writeText(
      defect.inspectionResult.remark,
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsTableColumnWidthMeasure - 2,
      columns.get('Measure'),
      baseY,
      0
    );
    this.writeText(
      deadlineText,
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsTableColumnWidthDeadline - 1,
      columns.get('Deadline'),
      baseY,
      0
    );
    baseY += maxLineHeight + ProtocolPdfConstants.defectsTableMarginBetweenDefects;
    return baseY;
  }

  private addDefectsTable(defects: ProtocolPdfResultDto[], baseY: number): number {
    baseY = this.addDefectPointGroupTitle(defects, baseY);
    const columns = this.getDefectsWithoutColumnDefectTableColumns();
    baseY = this.addDefectsWithoutColumnDefectTableHeader(columns, baseY);
    baseY += ProtocolPdfConstants.lineHeight;
    const tableContentFontType = this.getDefectTableParams().defectTableContentFontType;
    defects.forEach((defect) => {
      baseY = this.addDefectWithoutColumnDefectToTable(
        defect,
        tableContentFontType,
        baseY,
        columns
      );
    });
    return baseY;
  }

  private getDefectsWithoutColumnDefectTableColumns() {
    const columns = new Map<string, number>();
    columns.set('Number', ProtocolPdfConstants.marginX);
    columns.set(
      'Measure',
      columns.get('Number') + ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber
    );
    columns.set(
      'Deadline',
      columns.get('Measure') + ProtocolPdfConstants.defectsTableColumnWidthMeasureExtended
    );
    columns.set('Gve', columns.get('Deadline') + 25);
    return columns;
  }

  private addDefectWithoutColumnDefectToTable(
    defect: ProtocolPdfResultDto,
    tableContentFontType: string,
    baseY: number,
    columns: Map<string, number>
  ) {
    const deadlineText = defect.inspectionResult.complaintRemedyDateDue
      ? this.datePipe.transform(
          defect.inspectionResult.complaintRemedyDateDue,
          ProtocolPdfConstants.dateFormat
        )
      : '';
    let maxLineHeight = 0;
    const amountAffected =
      defect.inspectionResult.amountAffected !== null
        ? String(defect.inspectionResult.amountAffected)
        : '';

    maxLineHeight = this.getMaxLineHeightFromDefect(
      tableContentFontType,
      defect,
      deadlineText,
      amountAffected,
      maxLineHeight
    );
    if (baseY + maxLineHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }

    this.writeText(
      this.getPointNumber(defect),
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      ProtocolPdfConstants.defectsTableColumnWidthNumber - 1,
      columns.get('Number'),
      baseY,
      0
    );
    this.writeText(
      defect.inspectionResult.remark,
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      ProtocolPdfConstants.defectsTableColumnWidthMeasureExtended - 1,
      columns.get('Measure'),
      baseY,
      0
    );
    this.writeText(
      deadlineText,
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      ProtocolPdfConstants.defectsTableColumnWidthGve - 1,
      columns.get('Deadline'),
      baseY,
      0
    );
    this.writeText(
      amountAffected,
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      ProtocolPdfConstants.defectsTableColumnWidthGve - 1,
      columns.get('Gve'),
      baseY,
      0
    );
    baseY += maxLineHeight + ProtocolPdfConstants.defectsTableMarginBetweenDefects;
    return baseY;
  }

  private getMaxLineHeightFromDefect(
    tableContentFontType: string,
    defect: ProtocolPdfResultDto,
    deadlineText: string,
    amountAffected: string,
    maxLineHeight: number
  ) {
    const multiLineTextPointNumber = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      this.getPointNumber(defect),
      ProtocolPdfConstants.defectsTableColumnWidthNumber - 1
    );
    const multiLineTextRemark = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      defect.inspectionResult.remark,
      ProtocolPdfConstants.defectsTableColumnWidthMeasureExtended - 1
    );
    const multiLineTextDeadline = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      deadlineText,
      ProtocolPdfConstants.defectsTableColumnWidthGve - 1
    );
    const multiLineTextAmountAffected = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      amountAffected,
      ProtocolPdfConstants.defectsTableColumnWidthGve - 1
    );

    maxLineHeight =
      Math.max(
        multiLineTextPointNumber.length,
        multiLineTextRemark.length,
        multiLineTextDeadline.length,
        multiLineTextAmountAffected.length
      ) * ProtocolPdfConstants.lineHeight;
    return maxLineHeight;
  }

  private addDefectsWithoutColumnDefectTableHeader(columns: Map<string, number>, baseY: number) {
    const maxNbRows = 1;
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderNr'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthNumber,
      columns.get('Number'),
      baseY,
      maxNbRows
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderMeasure'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthMeasureExtended,
      columns.get('Measure'),
      baseY,
      maxNbRows
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderDeadline'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthGve,
      columns.get('Deadline'),
      baseY,
      maxNbRows
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectTableHeaderGve'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.italicFontType,
      ProtocolPdfConstants.defectsTableColumnWidthGve,
      columns.get('Gve'),
      baseY,
      maxNbRows
    );
    baseY += ProtocolPdfConstants.lineHeight / 2;
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
    return baseY;
  }

  private addDefectsCorrectionTable(
    defects: ProtocolPdfResultDto[],
    baseY: number,
    defectTableParams: DefectTableParams
  ): number {
    baseY = this.addDefectPointGroupTitle(defects, baseY);
    const columns = this.getDefectsCorrectionTableColumns();
    baseY = this.addDefectsCorrectionTableHeader(columns, baseY, defectTableParams);
    const tableContentFontType = this.getDefectTableParams().defectTableContentFontType;
    defects.forEach((defect) => {
      baseY = this.addDefectCorrectionToTable(
        defect,
        tableContentFontType,
        defectTableParams,
        baseY,
        columns
      );
    });
    return (baseY += 0.5 * ProtocolPdfConstants.lineHeight);
  }

  private addDefectCorrectionToTable(
    defect: ProtocolPdfResultDto,
    tableContentFontType: string,
    defectTableParams: DefectTableParams,
    baseY: number,
    columns: Map<string, number>
  ) {
    let maxLineHeight = 0;
    const deadlineText = defect.inspectionResult.complaintRemedyDateDue
      ? this.datePipe.transform(
          defect.inspectionResult.complaintRemedyDateDue,
          ProtocolPdfConstants.dateFormat
        )
      : '';
    maxLineHeight = this.getMaxLineHeightDefectCorrection(
      tableContentFontType,
      defect,
      deadlineText,
      maxLineHeight,
      defectTableParams
    );
    if (baseY + maxLineHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }

    this.writeText(
      this.getPointNumber(defect),
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber - 1,
      columns.get('Number'),
      baseY,
      0
    );
    if (defectTableParams.includeHeaderDefect) {
      this.writeText(
        defect.inspectionResult.defectDescription,
        this.getDefectTableParams().defaultTablesContentFontSize,
        tableContentFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect - 4,
        columns.get('Defect'),
        baseY,
        0
      );
      this.writeText(
        defect.inspectionResult.remark,
        this.getDefectTableParams().defaultTablesContentFontSize,
        tableContentFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure - 1,
        columns.get('Measure'),
        baseY,
        0
      );
    } else {
      this.writeText(
        defect.inspectionResult.remark,
        this.getDefectTableParams().defaultTablesContentFontSize,
        tableContentFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect +
          ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure -
          1,
        columns.get('Defect'),
        baseY,
        0
      );
    }
    this.writeText(
      deadlineText,
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDeadline - 1,
      columns.get('Deadline'),
      baseY,
      0
    );
    if (defectTableParams.useAcroFormsOnProtocol) {
      this.addAcroCheckBox(3, columns.get('Corrected'), baseY);
      const widthCorrectedDots =
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthCorrected - 4;
      this.addAcroTextbox({
        x: ProtocolPdfConstants.maxXValue - widthCorrectedDots,
        y: baseY,
        width: widthCorrectedDots,
      });
    } else {
      const correctedText = '[  ]';
      this.writeText(
        correctedText,
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthCorrected,
        columns.get('Corrected'),
        baseY,
        1
      );
      const widthCorrectedDots =
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthCorrected -
        this.pdfDocument.getTextWidth(correctedText) -
        3;
      this.writeDottedLine(
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        widthCorrectedDots,
        ProtocolPdfConstants.maxXValue - widthCorrectedDots,
        baseY + 0.8
      );
    }

    baseY = baseY + maxLineHeight + ProtocolPdfConstants.defectsTableMarginBetweenDefects;
    return baseY;
  }

  private getMaxLineHeightDefectCorrection(
    tableContentFontType: string,
    defect: ProtocolPdfResultDto,
    deadlineText: string,
    maxLineHeight: number,
    defectTableParams: DefectTableParams
  ) {
    const multiLineTextPointNumber = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      this.getPointNumber(defect),
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber - 1
    );
    const multiLineTextDefectDescription = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      defect.inspectionResult.defectDescription,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect - 4
    );
    const multiLineTextDefectRemark1 = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      defect.inspectionResult.remark,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure - 1
    );
    const multiLineTextDefectRemark2 = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      defect.inspectionResult.remark,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect +
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure -
        1
    );
    const multiLineTextDeadline = this.getMultiLineText(
      this.getDefectTableParams().defaultTablesContentFontSize,
      tableContentFontType,
      deadlineText,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDeadline - 1
    );

    maxLineHeight =
      Math.max(
        multiLineTextPointNumber.length,
        (defectTableParams.includeHeaderDefect ? multiLineTextDefectDescription : []).length,
        (defectTableParams.includeHeaderDefect
          ? multiLineTextDefectRemark1
          : multiLineTextDefectRemark2
        ).length,
        multiLineTextDeadline.length
      ) * ProtocolPdfConstants.lineHeight;
    return maxLineHeight;
  }

  private addDefectsCorrectionTableHeader(
    columns: Map<string, number>,
    baseY: number,
    defectTableParams: DefectTableParams
  ) {
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectCorrectionTableHeaderNr'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber,
      columns.get('Number'),
      baseY,
      1
    );
    if (defectTableParams.includeHeaderDefect) {
      this.writeText(
        this.translateService.instant('ProtocolPdf.DefectCorrectionTableHeaderDefect'),
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect,
        columns.get('Defect'),
        baseY,
        1
      );
      this.writeText(
        this.translateService.instant('ProtocolPdf.DefectCorrectionTableHeaderMeasure'),
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure,
        columns.get('Measure'),
        baseY,
        1
      );
    } else {
      this.writeText(
        this.translateService.instant('ProtocolPdf.DefectCorrectionTableHeaderMeasure'),
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect +
          ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure,
        columns.get('Defect'),
        baseY,
        1
      );
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.DefectCorrectionTableHeaderDeadline'),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDeadline,
      columns.get('Deadline'),
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant(defectTableParams.defectCorrectionTableHeaderCorrected),
      this.getDefectTableParams().defaultTablesContentFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthCorrected,
      columns.get('Corrected'),
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight / 2.0;
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  private getDefectsCorrectionTableColumns() {
    const columns = new Map<string, number>();
    columns.set('Number', ProtocolPdfConstants.marginX);
    columns.set(
      'Defect',
      columns.get('Number') + ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber
    );
    columns.set(
      'Measure',
      columns.get('Defect') + ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect
    );
    columns.set(
      'Deadline',
      columns.get('Measure') + ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure
    );
    columns.set(
      'Corrected',
      columns.get('Deadline') + ProtocolPdfConstants.defectsCorrectionTableColumnWidthDeadline
    );
    return columns;
  }

  private getStatementLinesHeight(protocol: ProtocolDto, multiLineRemarkLines: any) {
    const protocolPdfLabelStatement = 1;
    const statementLines =
      (protocol.statement.correspondToFacts ? 1 : 0) +
      (protocol.statement.noCorrespondToFacts ? 1 : 0) +
      (protocol.statement.agree ? 1 : 0) +
      (protocol.statement.disagree ? 1 : 0) +
      protocolPdfLabelStatement +
      multiLineRemarkLines;
    return statementLines * ProtocolPdfConstants.lineHeight + 1;
  }

  private hasStatement(protocol: ProtocolDto) {
    return (
      protocol.statement.correspondToFacts ||
      protocol.statement.noCorrespondToFacts ||
      protocol.statement.agree ||
      protocol.statement.disagree ||
      protocol.statement.remark
    );
  }

  private addDefectCorrectionHeader(
    inspection: InspectionWithResultsDto,
    firstDefectCorrectionPage: number
  ) {
    for (let i = firstDefectCorrectionPage; i <= this.nbPages; i++) {
      this.pdfDocument.setPage(i);
      let inspectionDetails = `${inspection.unitName}, ${inspection.unitTown}`;
      const defectTableParams = this.getDefectTableParams();
      if (defectTableParams.tvdIdOnHeader && inspection.unitTvdId) {
        inspectionDetails += `,  ${inspection.unitTvdId}`;
      }
      if (defectTableParams.ktIdOnHeader && inspection.unitCantonId) {
        inspectionDetails += `,  ${inspection.unitCantonId}`;
      }
      const inspectionDate = this.datePipe.transform(
        inspection.inspectionDate,
        ProtocolPdfConstants.dateFormat
      );
      this.pdfDocument.setFontSize(ProtocolPdfConstants.smallFontSize);
      this.setFontType(ProtocolPdfConstants.defaultFontType);
      if (defectTableParams.inspectionDetailsOnHeader) {
        this.pdfDocument.text(
          inspectionDetails,
          ProtocolPdfConstants.marginX,
          ProtocolPdfConstants.marginYFirstPage
        );
      }

      if (defectTableParams.inspectionDateOnHeader) {
        this.pdfDocument.text(
          inspectionDate,
          ProtocolPdfConstants.maxXValue,
          ProtocolPdfConstants.marginYFirstPage,
          { align: 'right' }
        );
      }
    }
  }

  private addDefectCorrectionRemarks(baseY: number, defectTableParams: DefectTableParams) {
    const minRemarksLines = defectTableParams.labelRemarks.length * this.getNewLine(1);
    const minRemarksHeight =
      2 * minRemarksLines * ProtocolPdfConstants.lineHeight - ProtocolPdfConstants.lineHeight;
    const maxRemarksYValue =
      ProtocolPdfConstants.footerLineYValue -
      this.calculateDefectCorrectionSignatureBlockHeight(defectTableParams);
    if (baseY + minRemarksHeight > maxRemarksYValue) {
      baseY = this.newPage();
    }
    let maxYValueDivider = defectTableParams.labelRemarks.length;
    const maximumLines = defectTableParams.maxLinesRemarks;
    defectTableParams.labelRemarks.forEach((labelText) => {
      let maxYValue = maxRemarksYValue / maxYValueDivider;
      if (maxYValueDivider > 1) {
        maxYValue =
          maxRemarksYValue / maxYValueDivider + baseY / defectTableParams.labelRemarks.length;
      }
      baseY = this.addSplittedMultilineTextWithCommentLines(
        baseY,
        maxYValue,
        labelText,
        maximumLines - 1
      );
      maxYValueDivider -= 1;
    });
  }

  private addSplittedMultilineTextWithCommentLines(
    baseY: number,
    maxYValue: number,
    labelText: string,
    maximumLines: number
  ): number {
    this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    const remarksLabelText = this.translateService.instant(labelText);
    const remarksLabelTextWidth = this.pdfDocument.getTextWidth(remarksLabelText) + 5;
    this.writeText(
      remarksLabelText,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      remarksLabelTextWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    if (this.getDefectTableParams().useAcroFormsOnProtocol) {
      baseY += ProtocolPdfConstants.lineHeight + 1;
      this.addAcroTextbox({
        x: ProtocolPdfConstants.marginX,
        y: baseY,
        width: ProtocolPdfConstants.maxLineWidth,
        height: maxYValue - baseY,
      });
      baseY = maxYValue + 1;
    } else {
      this.writeDottedLine(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.maxLineWidth - remarksLabelTextWidth - 3,
        ProtocolPdfConstants.marginX + remarksLabelTextWidth + 2,
        baseY
      );
      baseY += 2 * ProtocolPdfConstants.lineHeight;
      const newLines = 2 * ProtocolPdfConstants.lineHeight;
      while (baseY <= maxYValue) {
        if (maximumLines === 0) {
          break;
        }
        this.writeDottedLine(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          ProtocolPdfConstants.maxLineWidth,
          ProtocolPdfConstants.marginX,
          baseY
        );
        baseY += newLines;
        const baseYToCalculateNewLineRange = baseY;
        const isNewLineInRange = baseYToCalculateNewLineRange + newLines <= maxYValue;
        if (!isNewLineInRange) {
          break;
        }
        maximumLines--;
      }
    }
    return baseY;
  }

  private addDefectCorrectionSignatureBlock(defectTableParams: DefectTableParams) {
    const margin2ndColumn =
      ProtocolPdfConstants.marginX + ProtocolPdfConstants.signatureBlockWidthLabel;
    const margin3rdColumn = margin2ndColumn + ProtocolPdfConstants.signatureBlockWidthDottedLine;
    const margin4thColumn = margin3rdColumn + ProtocolPdfConstants.signatureBlockWidthLabel;
    let y =
      ProtocolPdfConstants.footerLineYValue -
      this.calculateDefectCorrectionSignatureBlockHeight(defectTableParams) +
      2 * ProtocolPdfConstants.lineHeight;
    if (defectTableParams.addCheckboxPhotos) {
      const checkBox = new PdfDocumentCheckbox();
      checkBox.currentPositionY = y;
      checkBox.maxTextWidth = ProtocolPdfConstants.maxXValue;
      checkBox.positionX = ProtocolPdfConstants.maxXValue - 25;
      checkBox.text = this.translateService.instant('ProtocolPdf.LabelDocumentationPhotosAdded');
      this.addCheckbox(checkBox);
      y += 2 * ProtocolPdfConstants.lineHeight;
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelLocation'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel,
      ProtocolPdfConstants.marginX,
      y,
      1
    );
    if (defectTableParams.useAcroFormsOnProtocol) {
      this.addAcroTextbox({
        x: margin2ndColumn,
        y,
        width: ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
      });
    } else {
      this.writeDottedLine(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
        margin2ndColumn,
        y
      );
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel,
      margin3rdColumn,
      y,
      1
    );
    if (defectTableParams.useAcroFormsOnProtocol) {
      this.addAcroTextbox({
        x: margin4thColumn,
        y,
        width: ProtocolPdfConstants.signatureBlockWidthDottedLine,
      });
    } else {
      this.writeDottedLine(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
        margin4thColumn,
        y
      );
    }
    y += 3 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelName'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel,
      ProtocolPdfConstants.marginX,
      y,
      1
    );
    if (defectTableParams.useAcroFormsOnProtocol) {
      this.addAcroTextbox({
        x: margin2ndColumn,
        y,
        width: ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
      });
    } else {
      this.writeDottedLine(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.signatureBlockWidthDottedLine - 5,
        margin2ndColumn,
        y
      );
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignature'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel,
      margin3rdColumn,
      y,
      1
    );
    if (defectTableParams.useAcroFormsOnProtocol) {
      this.addAcroTextbox({
        x: margin4thColumn,
        y,
        width: ProtocolPdfConstants.signatureBlockWidthDottedLine,
      });
    } else {
      this.writeDottedLine(
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
        margin4thColumn,
        y
      );
    }
    y += ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.SublabelResponsiblePerson'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      y,
      1
    );
    y += 2 * ProtocolPdfConstants.lineHeight;
    const multiLineTextConfirmationDeadline = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant(defectTableParams.deadlineText),
      ProtocolPdfConstants.maxLineWidth - 6
    );
    this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    const yBoxUpperLine = y - ProtocolPdfConstants.lineHeight;
    multiLineTextConfirmationDeadline.forEach((textLine) => {
      this.pdfDocument.text(textLine, ProtocolPdfConstants.marginX + 3, y);
      y += ProtocolPdfConstants.lineHeight;
    });
    const yBoxLowerLine = y - 0.5 * ProtocolPdfConstants.lineHeight;
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      yBoxUpperLine,
      ProtocolPdfConstants.maxXValue,
      yBoxUpperLine
    );
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      yBoxUpperLine,
      ProtocolPdfConstants.marginX,
      yBoxLowerLine
    );
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      yBoxLowerLine,
      ProtocolPdfConstants.maxXValue,
      yBoxLowerLine
    );
    this.pdfDocument.line(
      ProtocolPdfConstants.maxXValue,
      yBoxUpperLine,
      ProtocolPdfConstants.maxXValue,
      yBoxLowerLine
    );
  }

  private calculateDefectTableHeight(
    defect: ProtocolPdfResultDto,
    defectTableParams: DefectTableParams
  ): number {
    let multiLinePointNumberTextLength = 0;
    let multiLineDefectTextLength = 0;
    let multiLineMeasureTextLength = 0;
    let multiLineDeadlineTextLength = 0;
    let multiLineAmountAffectedTextLength = 0;
    const deadlineText = defect.inspectionResult.complaintRemedyDateDue
      ? this.datePipe.transform(
          defect.inspectionResult.complaintRemedyDateDue,
          ProtocolPdfConstants.dateFormat
        )
      : '';
    const tableContentFontType = this.getDefectTableParams().defectTableContentFontType;
    multiLinePointNumberTextLength = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      tableContentFontType,
      this.getPointNumber(defect),
      ProtocolPdfConstants.defectsTableColumnWidthNumber
    ).length;
    multiLineDefectTextLength = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      tableContentFontType,
      defect.inspectionResult.defectDescription,
      ProtocolPdfConstants.defectsTableColumnWidthDefect - 3
    ).length;
    if (!defectTableParams.includeHeaderDefect) {
      multiLineMeasureTextLength = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        tableContentFontType,
        defect.inspectionResult.remark,
        ProtocolPdfConstants.defectsTableColumnWidthMeasureExtended
      ).length;
      multiLineDeadlineTextLength = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        tableContentFontType,
        deadlineText,
        ProtocolPdfConstants.defectsTableColumnWidthGve - 1
      ).length;
      const amountAffected =
        defect.inspectionResult.amountAffected !== null
          ? String(defect.inspectionResult.amountAffected)
          : '';
      multiLineAmountAffectedTextLength = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        tableContentFontType,
        amountAffected,
        ProtocolPdfConstants.defectsTableColumnWidthGve - 1
      ).length;
    } else {
      multiLineMeasureTextLength = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        tableContentFontType,
        defect.inspectionResult.remark,
        ProtocolPdfConstants.defectsTableColumnWidthMeasure - 2
      ).length;
      multiLineDeadlineTextLength = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        tableContentFontType,
        deadlineText,
        ProtocolPdfConstants.defectsTableColumnWidthDeadline - 1
      ).length;
    }
    let maxNbLines = Math.max(
      multiLinePointNumberTextLength,
      multiLineDefectTextLength,
      multiLineMeasureTextLength,
      multiLineDeadlineTextLength
    );
    if (!defectTableParams.includeHeaderDefect) {
      maxNbLines = Math.max(
        multiLinePointNumberTextLength,
        multiLineMeasureTextLength,
        multiLineDeadlineTextLength,
        multiLineAmountAffectedTextLength
      );
    }
    return (
      maxNbLines * ProtocolPdfConstants.lineHeight +
      ProtocolPdfConstants.defectsTableMarginBetweenDefects
    );
  }

  private calculateDefectCorrectionTableHeight(
    defect: ProtocolPdfResultDto,
    defectTableParams: DefectTableParams
  ): number {
    const tableContentFontType = this.getDefectTableParams().defectTableContentFontType;
    const multiLinePointNumberTextLength = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      tableContentFontType,
      this.getPointNumber(defect),
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber - 1
    ).length;
    let multiLineDefectTextLength = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      tableContentFontType,
      defect.inspectionResult.defectDescription,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect - 4
    ).length;
    let multiLineMeasureTextLength = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      tableContentFontType,
      defect.inspectionResult.remark,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure - 1
    ).length;
    if (!defectTableParams.includeHeaderDefect) {
      multiLineDefectTextLength = 0;
      multiLineMeasureTextLength = this.getMultiLineText(
        ProtocolPdfConstants.defaultFontSize,
        tableContentFontType,
        defect.inspectionResult.remark,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthDefect +
          ProtocolPdfConstants.defectsCorrectionTableColumnWidthMeasure -
          1
      ).length;
    }
    const deadlineText = defect.inspectionResult.complaintRemedyDateDue
      ? this.datePipe.transform(
          defect.inspectionResult.complaintRemedyDateDue,
          ProtocolPdfConstants.dateFormat
        )
      : '';
    const multiLineDeadlineTextLength = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      tableContentFontType,
      deadlineText,
      ProtocolPdfConstants.defectsCorrectionTableColumnWidthDeadline - 1
    ).length;
    const maxNbLines = Math.max(
      multiLinePointNumberTextLength,
      multiLineDefectTextLength,
      multiLineMeasureTextLength,
      multiLineDeadlineTextLength
    );
    return (
      maxNbLines * ProtocolPdfConstants.lineHeight +
      ProtocolPdfConstants.defectsTableMarginBetweenDefects
    );
  }

  private calculateFeesHeight(fees: ProtocolFeesDto, marginBemerkung: number): number {
    const marginSelectionTextColumn =
      ProtocolPdfConstants.marginX + ProtocolPdfConstants.firstColumnWidth;
    const feesSelectionText = this.getFeesSelectionText(fees);
    const multiLineFeesSelectionText = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      feesSelectionText,
      ProtocolPdfConstants.maxXValue - marginSelectionTextColumn
    );
    let calculatedHeight = multiLineFeesSelectionText.length * ProtocolPdfConstants.lineHeight;
    if (fees.feesSelection !== 0) {
      calculatedHeight += ProtocolPdfConstants.lineHeight * 1.5;

      if (fees.positions === undefined) {
        return calculatedHeight;
      }

      for (const position of fees.positions) {
        if (position.selected) {
          calculatedHeight += ProtocolPdfConstants.lineHeight;
        }
        const marginAnzahl = 69; //siehe marginAnzahl bei addFees
        const multiLinePosition = this.getMultiLineText(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          this.i18nTextPipe.transform(position.text),
          marginAnzahl - ProtocolPdfConstants.marginX - 1
        );
        const multiLineHoursReason = this.getMultiLineText(
          ProtocolPdfConstants.defaultFontSize,
          ProtocolPdfConstants.defaultFontType,
          position.hoursReason,
          ProtocolPdfConstants.maxXValue - marginBemerkung - 1
        );
        if (multiLinePosition.length > multiLineHoursReason.length) {
          calculatedHeight += (multiLinePosition.length - 1) * ProtocolPdfConstants.lineHeight;
        }
        if (multiLinePosition.length < multiLineHoursReason.length) {
          calculatedHeight += (multiLineHoursReason.length - 1) * ProtocolPdfConstants.lineHeight;
        }
      }
      calculatedHeight += 2 * ProtocolPdfConstants.lineHeight;
    }
    calculatedHeight = this.addTotalFeesToCalcultatedHeight(calculatedHeight);
    return calculatedHeight;
  }

  private addTotalFeesToCalcultatedHeight(calculatedHeight: number): number {
    return (calculatedHeight += 1 * ProtocolPdfConstants.lineHeight);
  }

  private getFeesSelectionText(fees: ProtocolFeesDto): string {
    let selectionText = '';
    if (fees.feesTypes) {
      selectionText = this.i18nTextPipe.transform(fees.feesTypes[fees.feesSelection]);
    }
    if (!selectionText) {
      selectionText = this.translateService.instant('Gebuehr.Keine');
    }
    return selectionText;
  }

  private calculateDefectCorrectionSignatureBlockHeight(
    defectTableParams: DefectTableParams
  ): number {
    let calculatedHeight = 8 * ProtocolPdfConstants.lineHeight;
    const multiLineTextConfirmationDeadline = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant(defectTableParams.deadlineText),
      ProtocolPdfConstants.maxLineWidth - 6
    );
    if (defectTableParams.addCheckboxPhotos) {
      calculatedHeight += 2 * ProtocolPdfConstants.lineHeight;
    }

    calculatedHeight += multiLineTextConfirmationDeadline.length * ProtocolPdfConstants.lineHeight;
    return calculatedHeight;
  }

  private getMultiLineCategoryText(defect: ProtocolPdfResultDto): string[] {
    let categoryText;
    if (defect.displayInspectionReason) {
      categoryText =
        this.i18nTextPipe.transform(defect.categoryName) +
        ' (' +
        this.i18nTextPipe.transform(defect.categoryShortName) +
        ', ' +
        this.i18nTextPipe.transform(defect.categoryReason) +
        ')';
    } else {
      categoryText =
        this.i18nTextPipe.transform(defect.categoryName) +
        ' (' +
        this.i18nTextPipe.transform(defect.categoryShortName) +
        ')';
    }

    return this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      categoryText,
      ProtocolPdfConstants.maxLineWidth
    );
  }

  private getMultiLineCategoryWithoutDefectText(
    category: ProtocolPdfCategoryWithoutDefectDto
  ): string[] {
    let categoryText;
    if (category.displayInspectionReason) {
      categoryText =
        this.i18nTextPipe.transform(category.categoryName) +
        ' (' +
        this.i18nTextPipe.transform(category.categoryShortName) +
        ', ' +
        this.i18nTextPipe.transform(category.categoryReason) +
        ')';
    } else {
      categoryText =
        this.i18nTextPipe.transform(category.categoryName) +
        ' (' +
        this.i18nTextPipe.transform(category.categoryShortName) +
        ')';
    }
    return this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      categoryText,
      ProtocolPdfConstants.maxLineWidth
    );
  }

  private getMultiLinePointGroupText(defect: ProtocolPdfResultDto): string[] {
    let pointGroupText = '';
    const pointGroupNames = defect.pointGroupNames;
    for (let i = 0; i < pointGroupNames.length; i++) {
      if (i > 0) {
        pointGroupText += ' - ';
      }
      pointGroupText += this.i18nTextPipe.transform(pointGroupNames[i]);
    }
    if (defect instanceof ProtocolPdfAggregatedResultDto) {
      let subPointGroupText = '';
      const aggregatedDefect = defect as ProtocolPdfAggregatedResultDto;
      for (let i = 0; i < aggregatedDefect.results.length; i++) {
        if (i > 0) {
          subPointGroupText += ', ';
        }
        subPointGroupText += this.i18nTextPipe.transform(
          aggregatedDefect.results[i].pointGroupNames[0]
        );
      }
      if (pointGroupText === '') {
        pointGroupText = subPointGroupText;
      } else {
        pointGroupText += ' (' + subPointGroupText + ')';
      }
    }
    return this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      pointGroupText,
      ProtocolPdfConstants.maxLineWidth
    );
  }

  private getMultiLineFurtherActionText(protocol: ProtocolDto): string[] {
    const maxWidth = ProtocolPdfConstants.maxLineWidth - ProtocolPdfConstants.firstColumnWidth;
    const mutliLineText = new Array<string>();
    const furtherActionDto = protocol.furtherAction;
    if (furtherActionDto) {
      if (furtherActionDto.noFurtherAction) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant('WeiteresVorgehen.KeineWeiterenSchritte'),
            maxWidth
          )
        );
      }
      if (furtherActionDto.repairsIndependently) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant(
              'WeiteresVorgehen.VerantwortlichePersonBehebtMaengelSelbstaendig'
            ),
            maxWidth
          )
        );
      }
      if (furtherActionDto.feedback) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant('WeiteresVorgehen.RueckmeldungFormular'),
            maxWidth
          )
        );
      }
      if (furtherActionDto.inspectionReport) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant('WeiteresVorgehen.KontrollberichtFolgt'),
            maxWidth
          )
        );
      }
      if (furtherActionDto.order) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant('WeiteresVorgehen.AnordnungVorgesehen'),
            maxWidth
          )
        );
      }
      if (furtherActionDto.criminalComplaint) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant('WeiteresVorgehen.StrafanzeigeFolgt'),
            maxWidth
          )
        );
      }
      if (furtherActionDto.followupInspection) {
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            this.translateService.instant('WeiteresVorgehen.NachkontrolleVorgesehen'),
            maxWidth
          )
        );
      }
      if (furtherActionDto.notification) {
        let text = this.translateService.instant('WeiteresVorgehen.Mitteilung');
        if (furtherActionDto.notificationText) {
          text += ' ' + furtherActionDto.notificationText;
        }
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            text,
            maxWidth
          )
        );
      }
      if (furtherActionDto.furtherAction) {
        let text = '';
        if (furtherActionDto.furtherActionText) {
          text += furtherActionDto.furtherActionText;
        }
        mutliLineText.push.apply(
          mutliLineText,
          this.getMultiLineText(
            ProtocolPdfConstants.defaultFontSize,
            ProtocolPdfConstants.defaultFontType,
            text,
            maxWidth
          )
        );
      }
    }
    return mutliLineText;
  }

  private writeLineWithSpecialCharacter(
    fontSize: number,
    fontType: string,
    maxWidth: number,
    x: number,
    y: number,
    character: string
  ): number {
    let dottedLine = '';
    this.pdfDocument.setFontSize(fontSize);
    this.setFontType(fontType);
    while (this.pdfDocument.getTextWidth(dottedLine + character) <= maxWidth) {
      dottedLine += character;
    }
    return this.writeText(dottedLine, fontSize, fontType, maxWidth, x, y, 1);
  }

  private calculateFieldValueTableHeight(
    fieldValues: ProtocolPdfResultDto[],
    categoryText: string[] = []
  ): number {
    let fieldValueTableHeight =
      2 * ProtocolPdfConstants.lineHeight + ProtocolPdfConstants.lineHeight / 2;
    const marginColumnNumber = ProtocolPdfConstants.marginX;
    const marginColumnName =
      marginColumnNumber + ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber;
    const marginColumnValue =
      marginColumnName + ProtocolPdfConstants.defectsTableColumnWidthMeasure;
    const widthFieldValue = ProtocolPdfConstants.maxXValue - marginColumnValue;

    if (categoryText.length > 0) {
      fieldValueTableHeight += categoryText.length * ProtocolPdfConstants.lineHeight;
    }

    fieldValues.forEach((fieldValue) => {
      const tableContentFontType = this.getDefectTableParams().defectTableContentFontType;
      const linesPointName = this.getMultiLineText(
        this.getDefectTableParams().defaultTablesContentFontSize,
        tableContentFontType,
        this.i18nTextPipe.transform(fieldValue.pointName),
        ProtocolPdfConstants.defectsTableColumnWidthMeasure - 2
      );
      const textValue = this.inspectionResultsService.getFieldValueOfLine(
        fieldValue.inspectionResult
      );
      const linesValue = this.getMultiLineText(
        this.getDefectTableParams().defaultTablesContentFontSize,
        tableContentFontType,
        textValue,
        widthFieldValue
      );
      fieldValueTableHeight +=
        Math.max(1, linesPointName.length, linesValue.length) * ProtocolPdfConstants.lineHeight;
    });

    return fieldValueTableHeight;
  }

  private addFieldValueTable(
    fieldValues: ProtocolPdfResultDto[],
    baseY: number,
    categoryText: string[] = []
  ): number {
    const marginColumnNumber = ProtocolPdfConstants.marginX;
    const marginColumnName =
      marginColumnNumber + ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber;
    const marginColumnValue =
      marginColumnName + ProtocolPdfConstants.defectsTableColumnWidthMeasure;
    const widthFieldValue = ProtocolPdfConstants.maxXValue - marginColumnValue;
    const maxNbRows = 1;
    if (categoryText.length === 0) {
      this.pdfDocument.line(
        ProtocolPdfConstants.marginX,
        baseY,
        ProtocolPdfConstants.maxXValue,
        baseY
      );
      baseY += ProtocolPdfConstants.lineHeight;
      this.writeText(
        this.translateService.instant('ProtocolPdf.DefectTableHeaderNr'),
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.italicFontType,
        ProtocolPdfConstants.defectsCorrectionTableColumnWidthNumber,
        marginColumnNumber,
        baseY,
        maxNbRows
      );
      this.writeText(
        this.translateService.instant('ProtocolPdf.HeaderName'),
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.italicFontType,
        ProtocolPdfConstants.defectsTableColumnWidthMeasure,
        marginColumnName,
        baseY,
        maxNbRows
      );
      this.writeText(
        this.translateService.instant('ProtocolPdf.LabelErfassterWert'),
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.italicFontType,
        widthFieldValue,
        marginColumnValue,
        baseY,
        maxNbRows
      );
    } else {
      this.writeMultiLineText(
        categoryText,
        ProtocolPdfConstants.defaultFontSize,
        ProtocolPdfConstants.italicFontType,
        ProtocolPdfConstants.marginX,
        baseY
      );
    }
    baseY += ProtocolPdfConstants.lineHeight / 2;
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;

    fieldValues.forEach((fieldValue) => {
      const linesPointName = this.getMultiLineText(
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        this.i18nTextPipe.transform(fieldValue.pointName),
        ProtocolPdfConstants.defectsTableColumnWidthMeasure - 2
      );

      const textValue = this.inspectionResultsService.getFieldValueOfLine(
        fieldValue.inspectionResult
      );
      const linesValue = this.getMultiLineText(
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        textValue,
        widthFieldValue
      );

      const maxY =
        Math.max(linesPointName.length, linesValue.length, 1) * ProtocolPdfConstants.lineHeight;
      if (baseY + maxY > ProtocolPdfConstants.maxYValue) {
        baseY = this.newPage();
      }

      this.writeText(
        fieldValue.pointNumber,
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        ProtocolPdfConstants.defectsTableColumnWidthNumber - 1,
        marginColumnNumber,
        baseY,
        0
      );

      this.writeMultiLineText(
        linesPointName,
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        marginColumnName,
        baseY
      );

      this.writeMultiLineText(
        linesValue,
        this.getDefectTableParams().defaultTablesContentFontSize,
        ProtocolPdfConstants.defaultFontType,
        marginColumnValue,
        baseY
      );
      baseY += maxY;
    });
    return (baseY += ProtocolPdfConstants.lineHeight * 0.5);
  }

  private hasResultDefects(results: Map<string, Map<string, ProtocolPdfResultDto[]>>): boolean {
    let hasDefectsOnResult = false;
    results.forEach((resultsPerCategory) => {
      hasDefectsOnResult = hasDefectsOnResult || this.hasDefectsOnCategory(resultsPerCategory);
    });
    return hasDefectsOnResult;
  }

  private hasDefectsOnCategory(categoryDefects: Map<string, ProtocolPdfResultDto[]>): boolean {
    let hasDefectsOnCategory = false;
    categoryDefects.forEach((d) => {
      hasDefectsOnCategory =
        hasDefectsOnCategory ||
        d.filter((a) => a.inspectionResult.inspectionResult === InspectionResultType.Defect)
          .length !== 0;
    });
    return hasDefectsOnCategory;
  }

  private addInspectionResultsWithoutDefectTitle(baseY: number, title: string): number {
    this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
    this.setFontType(ProtocolPdfConstants.boldFontType);
    this.pdfDocument.text(
      this.translateService.instant(title),
      ProtocolPdfConstants.marginX,
      baseY
    );
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    baseY += ProtocolPdfConstants.lineHeight * 1.8;
    return baseY;
  }

  private addInspectionResultsCategoriesResultTitle(
    baseY: number,
    titleCategories: string
  ): number {
    this.pdfDocument.setFontSize(ProtocolPdfConstants.defaultFontSize);
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    this.pdfDocument.text(titleCategories, ProtocolPdfConstants.marginX, baseY);
    baseY += 2;
    this.pdfDocument.setLineWidth(0.3);
    this.pdfDocument.line(
      ProtocolPdfConstants.marginX,
      baseY,
      ProtocolPdfConstants.maxXValue,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  private getCategoriesWithoutDefect(
    protocolPdf: ProtocolPdfDto
  ): ProtocolPdfCategoryWithoutDefectDto[] {
    const categories = new Array<ProtocolPdfCategoryWithoutDefectDto>();
    if (protocolPdf.inspection.categoryResults) {
      protocolPdf.inspection.categoryResults.forEach((resultsOfOneCategory) => {
        const categoryDefinition = protocolPdf.inspectionCategories.find(
          (category) => category.number === resultsOfOneCategory.categoryNumber
        );
        const displayResultWithoutDefect =
          this.inspectionCategoryCacheService.getDisplayResultWithoutDefectInProtocolPdf(
            resultsOfOneCategory.categoryNumber
          );
        const categoryHasOnlyFieldValuePoints =
          this.categoryHasOnlyFieldValuePoints(categoryDefinition);
        const categoryResult = this.getCategoryResult(resultsOfOneCategory, protocolPdf);
        const categoryResultIsDefect =
          categoryResult.inspectionResult === InspectionResultType.Defect ||
          categoryResult.inspectionResult === InspectionResultType.DefectInactive;
        const categoryResultIsNotApplicable =
          categoryResult.inspectionResult === InspectionResultType.NotApplicable;
        const categoryAllResultsAreNotApplicable = resultsOfOneCategory.results.every(
          (r) => r.inspectionResult === InspectionResultType.NotApplicable
        );
        const categoryHasZeroNotControlledResults = !resultsOfOneCategory.results.some(
          (r) => r.inspectionResult === InspectionResultType.NotControlled
        );
        let allNotControlledResultsAreAllowed = categoryHasZeroNotControlledResults;
        if (!categoryHasZeroNotControlledResults) {
          const notControlledInspectionElements = resultsOfOneCategory.results.filter(
            (r) => r.inspectionResult === InspectionResultType.NotControlled
          );
          allNotControlledResultsAreAllowed = !notControlledInspectionElements.some(
            (r) =>
              !protocolPdf.allowedNotControlledPointsInControlledCategory.find(
                (nc) => nc.key === r.inspectionElementKey
              )
          );
        }

        if (
          displayResultWithoutDefect &&
          !categoryHasOnlyFieldValuePoints &&
          !categoryResultIsDefect &&
          !(categoryResultIsNotApplicable || categoryAllResultsAreNotApplicable) &&
          (categoryHasZeroNotControlledResults || allNotControlledResultsAreAllowed)
        ) {
          const category = new ProtocolPdfCategoryWithoutDefectDto();
          category.categoryName =
            this.inspectionCategoryCacheService.getInspectionCategoryName(resultsOfOneCategory);
          category.categoryReason = resultsOfOneCategory.inspectionReason;
          category.categoryShortName =
            this.inspectionCategoryCacheService.getInspectionCategoryShortName(
              resultsOfOneCategory.categoryNumber
            );
          category.displayInspectionReason = protocolPdf.displayInspectionReason;
          categories.push(category);
        }
      });
    }
    return categories;
  }

  private getNkCategoryResultsDisplayedInProtocol(
    protocolPdf: ProtocolPdfDto
  ): ProtocolPdfCategoryWithoutDefectDto[] {
    const categories = new Array<ProtocolPdfCategoryWithoutDefectDto>();
    if (protocolPdf.inspection.categoryResults) {
      protocolPdf.inspection.categoryResults.forEach((resultsOfOneCategory) => {
        const categoryResult = this.getCategoryResult(resultsOfOneCategory, protocolPdf);
        if (categoryResult.inspectionResult === InspectionResultType.NotControlled) {
          const displayNkResultInProtocolPdf =
            this.inspectionCategoryCacheService.getDisplayNkResultInProtocolPdf(
              resultsOfOneCategory.categoryNumber
            );
          if (displayNkResultInProtocolPdf) {
            const category = new ProtocolPdfCategoryWithoutDefectDto();
            category.categoryName =
              this.inspectionCategoryCacheService.getInspectionCategoryName(resultsOfOneCategory);
            category.categoryReason = resultsOfOneCategory.inspectionReason;
            category.categoryShortName =
              this.inspectionCategoryCacheService.getInspectionCategoryShortName(
                resultsOfOneCategory.categoryNumber
              );
            category.displayInspectionReason = protocolPdf.displayInspectionReason;
            categories.push(category);
          }
        }
      });
    }
    return categories;
  }

  private getCategoryResult(
    categoryResult: FlatInspectionCategoryResultDto,
    protocolPdf: ProtocolPdfDto
  ): FlatInspectionResultDto {
    const categoryKey = protocolPdf.inspectionCategories.find(
      (category) => category.number === categoryResult.categoryNumber
    ).key;
    return categoryResult.results.find((result) => result.inspectionElementKey === categoryKey);
  }

  private categoryHasOnlyFieldValuePoints(inspectionElement: InspectionElementDto): boolean {
    if (inspectionElement.childElements && inspectionElement.childElements.length > 0) {
      let childElementsHaveOnlyFieldValuePoints = true;
      inspectionElement.childElements.forEach((childElement) => {
        const hasOnlyFieldValuePoints = this.categoryHasOnlyFieldValuePoints(childElement);
        if (!hasOnlyFieldValuePoints) {
          childElementsHaveOnlyFieldValuePoints = false;
        }
      });
      return childElementsHaveOnlyFieldValuePoints;
    } else {
      if (inspectionElement.fieldType) {
        return true;
      }
      return false;
    }
  }

  private calculateDefectsTableHeightWithTitle(
    baseY: number,
    inspectionResults: Map<string, Map<string, ProtocolPdfResultDto[]>>,
    inspectionResultForCategoryDto: InspectionResultForCategoryDto
  ): number {
    const firstDefect = this.getFirstResultDefect(inspectionResults);
    const calculatedHeight = this.calculateDefectsTableHeightWithPath(
      firstDefect,
      inspectionResultForCategoryDto.isDefectCorrectionTable,
      inspectionResultForCategoryDto.defectTableParams
    );
    const headerDefectsTableWithSpaces = ProtocolPdfConstants.lineHeight * 4;
    if (baseY + headerDefectsTableWithSpaces + calculatedHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    return baseY;
  }

  private getFirstResultDefect(
    results: Map<string, Map<string, ProtocolPdfResultDto[]>>
  ): ProtocolPdfResultDto[] {
    let result = null;
    results.forEach((resultsPerCategory) => {
      resultsPerCategory.forEach((d) => {
        if (this.hasDefectsOnCategory(resultsPerCategory) === true && result == null) {
          result = d;
        }
      });
    });
    return result;
  }

  private createInspectionResultForCategory(
    results: Map<string, Map<string, ProtocolPdfResultDto[]>>,
    defectTableParams: DefectTableParams
  ): InspectionResultForCategoryDto {
    const inspectionResultForCategoryDto = new InspectionResultForCategoryDto();
    inspectionResultForCategoryDto.defectsMap = results;
    inspectionResultForCategoryDto.defectTableParams = defectTableParams;
    inspectionResultForCategoryDto.isDefectCorrectionTable = false;
    return inspectionResultForCategoryDto;
  }

  private writeTextWithDocumentText(pdfDocumentText: PdfDocumentText): number {
    return this.writeText(
      pdfDocumentText.text,
      pdfDocumentText.fontSize,
      pdfDocumentText.fontType,
      pdfDocumentText.maxTextWidth,
      pdfDocumentText.positionX,
      pdfDocumentText.positionY,
      pdfDocumentText.maxNewTextLines,
      pdfDocumentText.prefix,
      pdfDocumentText.multiLinesPrefix
    );
  }

  private writeMultiTextWithLabels(
    labelAndTexts: Map<string, string>,
    positionY: number,
    labelMaxTextWidth: number,
    labelPositionX: number,
    valueMaxTextWidth: number,
    valuePositionX: number
  ): number {
    for (const [labelText, valueText] of labelAndTexts) {
      positionY = this.writeTextWithLabel(
        positionY,
        labelText,
        labelMaxTextWidth,
        labelPositionX,
        valueText,
        valueMaxTextWidth,
        valuePositionX,
        ProtocolPdfConstants.smallFontSize
      );
    }
    return positionY;
  }

  private writeTextWithLabel(
    positionY: number,
    labelText: string,
    labelMaxTextWidth,
    labelPositionX: number,
    valueText: string,
    valueMaxTextWidth,
    valuePositionX: number,
    labelFontSize?: number
  ): number {
    const label = new PdfDocumentText();
    label.text = labelText;
    label.maxTextWidth = labelMaxTextWidth;
    label.positionX = labelPositionX;
    label.positionY = positionY;
    label.maxNewTextLines = this.getMultiLineText(
      label.fontSize,
      label.fontType,
      label.text,
      label.maxTextWidth
    ).length;

    if (labelFontSize !== undefined) {
      label.fontSize = labelFontSize;
    }

    const value = new PdfDocumentText();
    value.text = valueText;
    value.maxTextWidth = valueMaxTextWidth;
    value.positionX = valuePositionX;
    value.positionY = positionY;
    value.maxNewTextLines = this.getMultiLineText(
      value.fontSize,
      value.fontType,
      value.text,
      value.maxTextWidth
    ).length;

    const baseYLabel = this.writeText(
      label.text,
      label.fontSize,
      label.fontType,
      label.maxTextWidth,
      label.positionX,
      label.positionY,
      label.maxNewTextLines
    );

    const baseYValue = this.writeText(
      value.text,
      value.fontSize,
      value.fontType,
      value.maxTextWidth,
      value.positionX,
      value.positionY,
      value.maxNewTextLines
    );

    return Math.max(baseYLabel, baseYValue) + ProtocolPdfConstants.lineHeight;
  }

  public abstract generateProtocolPdf(protocolPdfDto: ProtocolPdfDto);

  protected abstract addPageHeader(): number;

  protected abstract addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number;

  protected abstract getDefectTableParams(): DefectTableParams;
}
