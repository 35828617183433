import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { InspectionResultFieldValueDto } from '../core/api/model/inspectionResultFieldValueDto';
import { InspectionResultPointDto } from '../dtos/inspectionResultPointDto';
import { InspectionElementFieldType } from '../core/api/model/inspectionElementFieldType';
import { I18NText } from '@core/api';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';

@Component({
  selector: 'app-detail-list-point',
  templateUrl: './detail-list-point.component.html',
  styleUrls: ['./detail.component.scss', 'detail-list.component.scss'],
})
export class DetailListPointComponent implements OnInit {
  @Input()
  public point: InspectionResultPointDto;

  @Input()
  public readonly = false;

  @Input()
  public displayCopyFieldValueNumber = false;

  @Output()
  public resultChange: EventEmitter<any> = new EventEmitter();

  @Output()
  public copyValue: EventEmitter<any> = new EventEmitter();

  public fieldTypes = InspectionElementFieldType;

  private previousFieldValue: InspectionResultFieldValueDto;

  public constructor(private i18nTextPipe: I18nTextPipe) {}

  public onPointResultChanged(): void {
    this.resultChange.emit();
  }

  public onCopyFieldValueNumber(): void {
    this.copyValue.emit();
  }

  public onPointFieldValueChanged(): void {
    let hasFieldValueChanged = false;
    const newPointResult = this.point.pointResult.fieldValue;
    this.point.previousFieldValue = Object.assign({}, this.previousFieldValue);

    switch (newPointResult.inspectionElementFieldType) {
      case 'date':
        if (newPointResult.dateValue !== this.previousFieldValue.dateValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.dateValue = newPointResult.dateValue;
        }
        break;
      case 'number':
        if (newPointResult.numberValue !== this.previousFieldValue.numberValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.numberValue = newPointResult.numberValue;
        }
        break;
      case 'text':
        if (newPointResult.textValue !== this.previousFieldValue.textValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.textValue = newPointResult.textValue;
        }
        break;
      case 'yesNo':
        if (newPointResult.yesNoValue !== this.previousFieldValue.yesNoValue) {
          hasFieldValueChanged = true;
          this.previousFieldValue.yesNoValue = newPointResult.yesNoValue;
        }
        break;
    }

    if (hasFieldValueChanged) {
      this.resultChange.emit();
    }
  }

  public ngOnInit(): void {
    if (this.point.pointResult.fieldValue) {
      const fieldValue = this.point.pointResult.fieldValue;
      this.previousFieldValue = {
        dateValue: fieldValue.dateValue,
        inspectionElementFieldType: fieldValue.inspectionElementFieldType,
        numberValue: fieldValue.numberValue,
        textValue: fieldValue.textValue,
        yesNoValue: fieldValue.yesNoValue,
      };
    }
  }

  public isTextFieldValueSuggestionEmpty(): boolean {
    return (
      this.point.textFieldValueSuggestions === null ||
      this.point.textFieldValueSuggestions.length <= 0
    );
  }

  public getTextFieldValueSuggestionTranslation(): I18NText[] {
    return this.point.textFieldValueSuggestions;
  }

  public insertTextFieldValueSuggestion(textFieldSuggestion: I18NText): void {
    const suggestion = this.i18nTextPipe.transform(textFieldSuggestion);
    if (!this.point.isTextReadonly) {
      const value = this.point.pointResult.fieldValue.textValue
        ? this.point.pointResult.fieldValue.textValue + ', '
        : '';
      this.point.pointResult.fieldValue.textValue = `${value}${suggestion}`;
    } else {
      this.point.pointResult.fieldValue.textValue = suggestion;
    }
  }
}
