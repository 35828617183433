import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { InspectionResultListDto } from '../dtos/inspectionResultListDto';
import { InspectionResultListPointGroupDto } from '../dtos/inspectionResultListPointGroupDto';
import { InspectionResultPointDto } from '../dtos/inspectionResultPointDto';
import { InspectionResultSummaryDto } from '../dtos/inspectionResultSummaryDto';
import { InspectionResultListItemDto } from '../dtos/inspectionResultListItemDto';
import { I18nTextPipe } from '../core/pipes/i18n-text.pipe';
import { FieldValueChangedEventDto } from '../dtos/fieldValueChangedEventDto';
import { DetailBaseComponent } from './detail-base.component';
import { ActivatedRoute } from '@angular/router';
import { FlatInspectionResultDto } from '../core/api/model/flatInspectionResultDto';
import { I18NText } from '../core/api/model/i18NText';
import { InspectionElementDto } from '../core/api/model/inspectionElementDto';
import { InspectionResultType } from '../core/api/model/inspectionResultType';
import { InspectionResultElementType } from '../core/api/model/inspectionResultElementType';
import { PointGroupType } from '../core/api/model/pointGroupType';
import { InspectionElementFieldType } from '../core/api/model/inspectionElementFieldType';
import { ClientConfigDBService } from '../core/db/client-config-db-service';
import { InspectionCategoryCacheService } from '@core/cache/inspection-category-cache-service';

@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.scss', './detail.component.scss'],
})
export class DetailListComponent extends DetailBaseComponent implements OnInit, OnChanges {
  @Output()
  public changeFieldValue: EventEmitter<FieldValueChangedEventDto> =
    new EventEmitter<FieldValueChangedEventDto>();

  public list: InspectionResultListDto;

  public notApplicablePointGroupsDisplayed = false;

  public pointGroupTypes = PointGroupType;

  public fieldTypes = InspectionElementFieldType;

  public constructor(
    i18nTextPipe: I18nTextPipe,
    route: ActivatedRoute,
    clientConfigDBService: ClientConfigDBService,
    inspectionCategoryCacheService: InspectionCategoryCacheService
  ) {
    super(i18nTextPipe, route, clientConfigDBService, inspectionCategoryCacheService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.notApplicablePointGroupsDisplayed = this.notApplicableCategoriesDisplayed;
    this.buildList();
  }

  public ngOnChanges(changes: any): void {
    if (
      changes.notApplicableCategoriesDisplayed &&
      changes.notApplicableCategoriesDisplayed.currentValue
    ) {
      this.notApplicablePointGroupsDisplayed = true;
      this.buildList();
    }
  }

  public onPointGroupResultChanged(pointGroupItem: InspectionResultListItemDto): void {
    setTimeout(() => {
      const pointGroupElement = this.getInspectionElement(
        this.inspectionCategory,
        pointGroupItem.key
      );
      this.propagateResultToChildren(
        pointGroupElement,
        pointGroupItem.pointGroup.pointGroupResult.inspectionResult,
        true
      );
      this.resetParentResult(pointGroupItem.parentKey, pointGroupItem.pointGroup.pointGroupResult);
      this.handleDefects();
      this.save.emit();
      this.buildList();
    }, 0);
  }

  public rowPointGroupResultChanged(pointItem: InspectionResultListItemDto): void {
    const pointGroupElement = this.getInspectionElement(this.inspectionCategory, pointItem.key);
    this.propagateResultToChildren(
      pointGroupElement,
      pointItem.pointGroup.pointGroupResult.inspectionResult,
      true
    );
    this.resetParentResult(pointItem.parentKey, pointItem.pointGroup.pointGroupResult);
    this.handleDefects();
    this.save.emit();
  }

  public onPointResultChanged(pointItem: InspectionResultListItemDto): void {
    setTimeout(() => {
      this.resetParentResult(pointItem.parentKey, pointItem.point.pointResult);
      if (pointItem.point.pointResult.fieldValue) {
        const fieldValue = pointItem.point.pointResult.fieldValue;
        if (
          fieldValue.inspectionElementFieldType === InspectionElementFieldType.Text ||
          fieldValue.inspectionElementFieldType === InspectionElementFieldType.Number ||
          fieldValue.inspectionElementFieldType === InspectionElementFieldType.YesNo
        ) {
          this.changeFieldValue.emit({
            categoryNumber: this.categoryDetail.categoryNumber,
            pointResult: pointItem.point.pointResult,
            previousFieldValue: pointItem.point.previousFieldValue,
          });
        }
      }
      this.handleDefects();
      this.save.emit();
    }, 0);
  }

  public onCategoryResultChanged(propagate: boolean): void {
    if (propagate) {
      this.propagateResultToChildren(
        this.inspectionCategory,
        this.list.summary.categoryResult.inspectionResult
      );
    }
    this.handleDefects();
    this.save.emit();
    this.buildList();
  }

  public displayCopyFieldValueNumber(pointItem: InspectionResultListItemDto): boolean {
    if (!this.copyNumberValueEnabled) {
      return false;
    }
    const results = this.list.items.filter(
      (i) =>
        i.parentKey === pointItem.parentKey &&
        i.point?.pointResult?.fieldValue?.inspectionElementFieldType ===
          InspectionElementFieldType.Number
    );
    return results.length > 1;
  }

  public onCopyFieldValueNumber(pointItem: InspectionResultListItemDto): void {
    const results = this.list.items.filter(
      (i) =>
        i.parentKey === pointItem.parentKey &&
        i.point?.pointResult?.fieldValue?.inspectionElementFieldType ===
          InspectionElementFieldType.Number
    );
    results.forEach((result) => {
      const numberValue = result.point.pointResult.fieldValue.numberValue;
      if (numberValue === undefined || numberValue === null) {
        result.point.pointResult.fieldValue.numberValue =
          pointItem.point.pointResult.fieldValue.numberValue;
        this.changeFieldValue.emit({
          categoryNumber: this.categoryDetail.categoryNumber,
          pointResult: result.point.pointResult,
          previousFieldValue: result.point.previousFieldValue,
        });
      }
    });
    this.save.emit();
    this.buildList();
  }

  public onNotApplicablePointGroupsDisplayedChanged(): void {
    this.notApplicablePointGroupsDisplayed = !this.notApplicablePointGroupsDisplayed;
    this.buildList();
  }

  public buildList(): void {
    this.list = {
      items: this.getChildListItems(this.inspectionCategory),
      summary: this.getCategorySummary(),
    };
  }

  public getDependingResultsForPointGroup(
    pointGroup: InspectionResultListPointGroupDto
  ): FlatInspectionResultDto[] {
    const results = pointGroup.points
      .filter((point) => point.pointResult)
      .map((point) => point.pointResult);
    if (pointGroup.pointGroups) {
      pointGroup.pointGroups.forEach((childPointGroup) => {
        results.push.apply(results, this.getDependingResultsForPointGroup(childPointGroup));
      });
    }
    return results;
  }

  public hasOnlyFieldValuePoints(pointGroup: InspectionResultListPointGroupDto): boolean {
    return this.getDependingResultsForPointGroup(pointGroup).every(
      (result) => result.fieldValue != null
    );
  }

  public displayRemark(inspectionResultPoint: InspectionResultPointDto): boolean {
    return (
      inspectionResultPoint.pointResult.fieldValue?.inspectionElementFieldType ===
        this.fieldTypes.Number && inspectionResultPoint.displayRemarkOfNumberFieldValue === true
    );
  }

  private getChildListItems(
    inspectionElement: InspectionElementDto
  ): InspectionResultListItemDto[] {
    const listItems = new Array<InspectionResultListItemDto>();
    if (inspectionElement.childElements) {
      const pointGroups = inspectionElement.childElements.filter(
        (child) => child.type === InspectionResultElementType.PointGroup
      );
      const points = inspectionElement.childElements.filter(
        (child) => child.type === InspectionResultElementType.Point
      );
      points.forEach((point) => {
        const pointGroupNumber =
          inspectionElement.type === InspectionResultElementType.Category
            ? null
            : inspectionElement.number;
        const defectDescriptionSuggestions = this.getDefectDescriptionSuggestions(
          pointGroupNumber,
          point.number
        );
        const textFieldValueSuggestions = this.getTextFieldValueSuggestions(
          pointGroupNumber,
          point.number
        );
        const displayRemarkOfNumberFieldValue = this.getDisplayRemarkOfNumberFieldValue(
          pointGroupNumber,
          point.number
        );
        const isTextReadonly = this.getIsTextSetToReadonly(pointGroupNumber, point.number);
        const listPoint = this.getListPoint(
          point,
          defectDescriptionSuggestions,
          textFieldValueSuggestions,
          displayRemarkOfNumberFieldValue,
          isTextReadonly
        );
        listItems.push({
          key: point.key,
          parentKey: inspectionElement.key,
          point: listPoint,
          pointGroup: null,
        });
      });
      pointGroups.forEach((pointGroup) => {
        const listPointGroup = this.getListPointGroup(pointGroup);
        if (
          this.notApplicablePointGroupsDisplayed ||
          !listPointGroup.pointGroupResult ||
          listPointGroup.pointGroupResult.inspectionResult !== InspectionResultType.NotApplicable
        ) {
          listItems.push({
            key: pointGroup.key,
            parentKey: inspectionElement.key,
            point: null,
            pointGroup: listPointGroup,
          });
          listItems.push.apply(listItems, this.getChildListItems(pointGroup));
        }
      });
    }
    return listItems;
  }

  private getListPointGroup(pointGroup: InspectionElementDto): InspectionResultListPointGroupDto {
    const pointGroups = new Array<InspectionResultListPointGroupDto>();
    const points = new Array<InspectionResultPointDto>();
    if (pointGroup.childElements) {
      pointGroup.childElements.forEach((child) => {
        if (child.type === InspectionResultElementType.PointGroup) {
          pointGroups.push(this.getListPointGroup(child));
        } else if (child.type === InspectionResultElementType.Point) {
          points.push(this.getListPoint(child));
        }
      });
    }
    const emptyResult: FlatInspectionResultDto = {
      amountAffected: null,
      complaintRemedyDateDue: null,
      defectDescription: null,
      defectRepetition: null,
      defectSeverity: null,
      fieldValue: null,
      inspectionElementKey: null,
      inspectionResult: InspectionResultType.NotDefined,
      livestockOwnerFeedbackDateDue: null,
      remark: null,
    };
    return {
      pointGroupNumber: pointGroup.number,
      pointGroupName: pointGroup.name,
      pointGroupDescription: pointGroup.description,
      pointGroupDescriptionDisplayed: false,
      pointGroupType: pointGroup.pointGroupType,
      pointGroups,
      points,
      pointGroupResult: this.getResult(pointGroup.key) ?? emptyResult,
    };
  }

  private getListPoint(
    point: InspectionElementDto,
    defectDescriptionSuggestions: I18NText[] = null,
    textFieldValueSuggestions: I18NText[] = null,
    displayRemarkOfNumberFieldValue: boolean = false,
    isTextReadonly: boolean = false
  ): InspectionResultPointDto {
    return {
      pointNumber: point.number,
      pointName: point.name,
      pointDescription: point.description,
      pointDescriptionDisplayed: false,
      pointResult: this.getResult(point.key),
      defectDescriptionSuggestions,
      textFieldValueSuggestions,
      isFkp: point.isFkp,
      displayRemarkOfNumberFieldValue,
      previousFieldValue: null,
      isTextReadonly,
    };
  }

  private getCategorySummary(): InspectionResultSummaryDto {
    return {
      categoryDescription: this.inspectionCategory.description,
      categoryDescriptionDisplayed: false,
      categoryResult: this.getResult(this.inspectionCategory.key),
    };
  }

  private resetParentResult(parentKey: string, childResult: FlatInspectionResultDto) {
    if (parentKey !== this.inspectionCategory.key) {
      const parentListItem = this.list.items.find((item) => item.key === parentKey);
      if (parentListItem && parentListItem.pointGroup) {
        if (parentListItem.pointGroup.pointGroupType === PointGroupType.Regular) {
          const pointGroupResult = parentListItem.pointGroup.pointGroupResult;
          if (
            pointGroupResult &&
            pointGroupResult.inspectionResult !== childResult.inspectionResult
          ) {
            pointGroupResult.inspectionResult = InspectionResultType.NotDefined;
          }
        }
        this.resetParentResult(parentListItem.parentKey, childResult);
      }
    } else {
      const categoryResult = this.list.summary.categoryResult;
      const resultChanged = this.setCategoryResultIfAllPointsAreSame(
        categoryResult,
        childResult.inspectionResult
      );
      if (!resultChanged) {
        if (
          categoryResult &&
          categoryResult.inspectionResult !== childResult.inspectionResult &&
          this.isResultNotApplicableOrNotControlled(categoryResult)
        ) {
          categoryResult.inspectionResult = InspectionResultType.NotDefined;
        }
      }
    }
  }
}
