import { Constants } from '@core/constants';
import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api/model/models';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';

export class ProtocolPdfFrBaseService extends ProtocolPdfStandardService {
  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader() + this.getNewLine(2, 2);
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderReport');
    baseY = this.addEmailSaav(baseY);
    baseY = this.addInspectionPersonInformation(protocolPdfDto.inspection, baseY);
    baseY = this.addInspectionHeader(
      protocolPdfDto.inspection,
      protocolPdfDto.protocol,
      baseY,
      true
    );
    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    const defects = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defect,
    });
    baseY = this.addInspectionResults(protocolPdfDto, defects, baseY);
    const fieldValues = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.fieldValue,
    });
    baseY = this.addFieldValues(fieldValues, baseY);
    baseY = this.addNkCategoryResultsDisplayedInProtocol(protocolPdfDto, baseY);
    baseY = this.addCommentOfDepartment(protocolPdfDto, baseY);
    baseY = this.addStatement(protocolPdfDto.protocol, baseY) + this.getNewLine(1);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(2);
    baseY = this.addOrderVet(baseY);
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY);
    baseY = this.addSaavCheckbox(baseY);
    baseY = this.addFurtherProcedureDetails(baseY);
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        defects
      );
    }
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save('kontrolle-' + protocolPdfDto.inspection.inspectionId + '.pdf');
    this.translateService.use(originalLanguage);
  }

  protected addPageHeader(): number {
    const marginHeaderColumn = 120;
    const lineHeight = 4;
    this.pdfDocument.setFont('helvetica');
    const imageData = this.getImageData(Constants.imageFr1);
    this.addImage(
      imageData,
      'jpg',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage,
      25,
      20
    );
    this.pdfDocument.setFontSize(9);
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderFr1'),
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage + 8 * lineHeight
    );
    this.pdfDocument.text(
      'Impasse de la Colline 4, 1762 Givisiez',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage + 9 * lineHeight
    );

    this.addAccredationLogo();

    this.setFontType(ProtocolPdfConstants.boldFontType);
    this.pdfDocument.text(
      'Service de la sécurité alimentaire',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage
    );
    this.pdfDocument.text(
      'et des affaires vétérinaires',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + lineHeight
    );
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    this.pdfDocument.text(
      'SAAV',
      marginHeaderColumn + 41,
      ProtocolPdfConstants.marginYFirstPage + lineHeight
    );
    this.setFontType(ProtocolPdfConstants.boldFontType);
    this.pdfDocument.text(
      'Amt für Lebensmittelsicherheit',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 2 * lineHeight
    );
    this.pdfDocument.text(
      'und Veterinärwesen',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 3 * lineHeight
    );
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    this.pdfDocument.text(
      'LSVW',
      marginHeaderColumn + 31,
      ProtocolPdfConstants.marginYFirstPage + 3 * lineHeight
    );
    this.pdfDocument.text(
      this.translateService.instant(this.getHeaderAddressTo()),
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 6 * lineHeight
    );
    this.pdfDocument.text(
      'T +41 26 305 80 70',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 8 * lineHeight
    );
    this.pdfDocument.text(
      this.translateService.instant('ProtocolPdf.HeaderFr4'),
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 9 * lineHeight
    );

    this.writeLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      75,
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage + 10 * lineHeight - 2
    );
    return 48;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    const multiLineTextResponsiblePersonPresent = this.getMultiLineText(
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.boldFontType,
      this.translateService.instant('ProtocolPdf.TextResponsiblePersonPresentFr'),
      ProtocolPdfConstants.maxLineWidth
    );
    const signatureBlockHeight =
      (multiLineTextResponsiblePersonPresent.length + 10) * ProtocolPdfConstants.lineHeight;
    const margin2ndColumn = ProtocolPdfConstants.marginX + 80;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    baseY = this.writeMultiLineText(
      multiLineTextResponsiblePersonPresent,
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.marginX,
      baseY,
      0
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeLine(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginX,
      baseY - 2
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    const dateText = this.datePipe.transform(
      inspection.inspectionDate,
      ProtocolPdfConstants.dateFormat
    );
    this.writeText(
      dateText,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX + 17,
      baseY,
      1
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureInspectionPerson'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelSignatureResponsiblePerson'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    baseY += 5 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addOrderVet(baseY: number): number {
    const marginFirstText = 150;
    const orderVetBlockHeight = 4 * ProtocolPdfConstants.lineHeight + this.getNewLine(1, 3);
    if (baseY + orderVetBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelOrderLSVW'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      marginFirstText,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    const multiLineTextDetail = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant(this.getTextOrderLSVW()),
      ProtocolPdfConstants.maxXValue
    );
    this.writeMultiLineText(
      multiLineTextDetail,
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX,
      baseY
    );
    baseY += 3 * ProtocolPdfConstants.lineHeight;
    return (baseY += this.getNewLine(1, 3));
  }

  protected addSaavCheckbox(baseY: number): number {
    if (baseY + ProtocolPdfConstants.lineHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFr3'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 100,
      ProtocolPdfConstants.marginX + 100,
      baseY,
      1
    );
    baseY += 4 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addFurtherProcedureDetails(baseY: number): number {
    const multiLineTextDetail1 = this.getMultiLineText(
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFr2'),
      ProtocolPdfConstants.maxXValue
    );
    const totalNbLines = multiLineTextDetail1.length;
    const totalHeight = (totalNbLines + 1) * ProtocolPdfConstants.lineHeight;
    if (baseY + totalHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextOptionFurtherProcedureDetailsFr1'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.boldFontType,
      40,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    baseY = this.writeMultiLineText(
      multiLineTextDetail1,
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.marginX,
      baseY
    );
    return baseY;
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineFr';
    defectTableParams.includeHeaderDefect = false;
    defectTableParams.tvdIdOnHeader = true;
    defectTableParams.defectTableRubricsFontType = ProtocolPdfConstants.defaultFontType;
    defectTableParams.defectTableContentFontType = ProtocolPdfConstants.boldFontType;
    defectTableParams.useAcroFormsOnProtocol = true;
    return defectTableParams;
  }

  protected addAccredationLogo() {
    const imageData = this.getImageData(Constants.imageFr2);
    this.addImage(
      imageData,
      'jpg',
      ProtocolPdfConstants.maxXValue - 25,
      ProtocolPdfConstants.marginYFirstPage - 4,
      20,
      20
    );
  }

  protected getHeaderAddressTo(): string {
    return 'ProtocolPdf.HeaderFr2';
  }

  protected getTextOrderLSVW(): string {
    return 'ProtocolPdf.TextOrderLSVW1';
  }

  private addEmailSaav(baseY: number): number {
    const margin3rdColumn = 140;
    const margin4thColumn = 160;
    baseY -= ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelEmail'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      margin4thColumn - margin3rdColumn,
      margin3rdColumn,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelEmailSAAV'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      margin4thColumn - margin3rdColumn,
      margin3rdColumn,
      baseY,
      1
    );
    this.writeText(
      'saav-sa@fr.ch',
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxXValue - margin4thColumn,
      margin4thColumn,
      baseY,
      1
    );
    return baseY;
  }
}
