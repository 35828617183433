<div class="modal-header bg-dark">
  <h4 class="modal-title" translate>Rubriken</h4>
  <button
    type="button"
    class="btn-close btn-close-white"
    aria-label="Close"
    (click)="modal.dismiss()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <label
      class="checkbox mb-0"
      formArrayName="categories"
      *ngFor="let category of categoriesFormArray.controls; let i = index">
      <input type="checkbox" [formControlName]="i" (change)="onCheckboxChange()" />
      <span class="checkmark"></span>
      <span class="checkbox-label"
        >{{ inspectionCategories[i].number }} {{ inspectionCategories[i].name | i18nText }}</span
      >
    </label>
    <hr />
    <form-row column-classes="col-md-2,col-md-10">
      <form-control labelKey="Kontrolle.Grund">
        <select formControlName="inspectionReason" class="form-select">
          <option *ngFor="let inspectionReason of inspectionReasons" [ngValue]="inspectionReason">
            {{ inspectionReason.name | i18nText }}
          </option>
        </select>
      </form-control>
    </form-row>
  </form>

  <div class="modal-footer">
    <div>
      <button class="btn btn-primary" (click)="submit()" [appDisable]="!enableSubmit">
        <span class="fas fa-plus-circle"></span>
        <span translate>Rubriken übernehmen</span>
      </button>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-outline-primary"
        (click)="modal.dismiss()"
        appAutofocus
        translate>
        Abbrechen
      </button>
    </div>
  </div>
</div>
