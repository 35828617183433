import { InspectionWithResultsDto } from '../api/model/models';
import { ProtocolPdfFrBaseService } from './protocol-pdf-fr-base.service';

export class ProtocolPdfFrService extends ProtocolPdfFrBaseService {
  protected getInspectionHeaderUnitName(inspection: InspectionWithResultsDto) {
    return (
      (inspection.unitResponsiblePersonFirstName ? inspection.unitResponsiblePersonFirstName : '') +
      (inspection.unitResponsiblePersonSurname ? ' ' + inspection.unitResponsiblePersonSurname : '')
    );
  }

  protected getInspectionHeaderAddress(inspection: InspectionWithResultsDto) {
    return (
      (inspection.unitResponsiblePersonStreetName
        ? inspection.unitResponsiblePersonStreetName
        : '') +
      (inspection.unitResponsiblePersonStreetNumber
        ? ' ' + inspection.unitResponsiblePersonStreetNumber
        : '')
    );
  }

  protected getInspectionHeaderPostalZoneTown(inspection: InspectionWithResultsDto) {
    return (
      (inspection.unitResponsiblePersonPostalCode
        ? inspection.unitResponsiblePersonPostalCode + ' '
        : '') + (inspection.unitResponsiblePersonTown ? inspection.unitResponsiblePersonTown : '')
    );
  }

  protected getInspectionHeaderEmail(inspection: InspectionWithResultsDto): string {
    return inspection.unitResponsiblePersonEmail;
  }

  protected getInspectionHeaderPhone(inspection: InspectionWithResultsDto) {
    return inspection.unitResponsiblePersonWorkPhones;
  }
}
