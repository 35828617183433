<button class="btn btn-link btn-ellipsis-link btn-with-badge" (click)="openDialog(resultRemark)">
  <span
    ngbPopover="{{ result.defectDescription }}"
    triggers="mouseenter:mouseleave"
    placement="right"
    class="badge badge-check-ellipsis fas fa-check"
    [ngClass]="{'badge-check-ellipsis-invisible' : !((result.inspectionResult === resultTypes.Defect && result.defectDescription) ||
      (result.inspectionResult !== resultTypes.Defect && result.remark))}"></span>
    <span class="fas fa-ellipsis-h"></span>
</button>

<ng-template #resultRemark>
  <div class="modal-header bg-dark">
    <h4 class="modal-title" translate *ngIf="result.inspectionResult !== resultTypes.Defect">
      Bemerkung
    </h4>
    <h4 class="modal-title" translate *ngIf="result.inspectionResult === resultTypes.Defect">
      Mangelbeschreibung
    </h4>
    <button
      type="button"
      class="btn-close btn-close-white"
      aria-label="Close"
      (click)="modal.dismiss()"
      [disabled]="form.invalid"></button>
  </div>
  <div class="modal-body">
    <div class="container-fluid ms-0 ps-0 me-0 pe-0">
      <form #form="ngForm" (focusout)="saveResult()">
        <form-control *ngIf="result.inspectionResult !== resultTypes.Defect">
          <textarea
            appAutofocus
            name="inspectionRemark"
            rows="5"
            maxlength="2000"
            [(ngModel)]="result.remark"
            [disabled]="readonly"></textarea>
        </form-control>
        <form-control *ngIf="result.inspectionResult === resultTypes.Defect">
          <textarea
            appAutofocus
            name="inspectionDefectDescription"
            rows="5"
            maxlength="2000"
            [(ngModel)]="result.defectDescription"
            [disabled]="readonly"></textarea>
        </form-control>
        <div class="row">
          <div class="col">
            <button
              *ngIf="elementName && result.inspectionResult === resultTypes.Defect"
              type="button"
              class="btn btn-primary"
              (click)="insertElementName()"
              [disabled]="readonly"
              translate>
              Punkt-Name einfügen
            </button>
            <div
              ngbDropdown
              class="d-inline-block"
              *ngIf="
                result.inspectionResult === resultTypes.Defect && defectDescriptionSuggestions
              ">
              <button
                class="btn btn-primary"
                id="dropdownSuggestions"
                ngbDropdownToggle
                [disabled]="readonly">
                Vorschlag einfügen
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownSuggestions">
                <button
                  class="dropdown-item"
                  (click)="insertDefectDescriptionSuggestion(defectDescriptionSuggestion)"
                  ngbDropdownItem
                  *ngFor="let defectDescriptionSuggestion of defectDescriptionSuggestions">
                  {{ defectDescriptionSuggestion | i18nText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="modal.dismiss()"
      [disabled]="form.invalid"
      translate>
      Schliessen
    </button>
  </div>
</ng-template>
