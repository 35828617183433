import { TranslateService } from '@ngx-translate/core';
import { InspectionCategoryCacheService } from '@core/cache/inspection-category-cache-service';
import { I18nTextPipe } from '@core/pipes/i18n-text.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { InspectionResultsService } from './inspection-results-service';
import { ImagesDto } from 'src/app/dtos/imagesDto';

export class ProtocolPdfServiceConstructorParams {
  public translateService: TranslateService;
  public deadlineText: string;
  public inspectionCategoryCacheService: InspectionCategoryCacheService;
  public i18nTextPipe: I18nTextPipe;
  public datePipe: DatePipe;
  public decimalPipe: DecimalPipe;
  public inspectionResultsService: InspectionResultsService;
  public pdfImages: ImagesDto;
}
