import { InspectionResultAccessor } from '@core/protocol-pdf/inspection-result-accessor';
import { DefectTableParams } from '@core/protocol-pdf/protocol-defect-table-params';
import { ProtocolPdfResultDto } from './ProtocolPdfResultDto';

export class InspectionResultForCategoryDto {
  public isDefectCorrectionTable: boolean;
  public isFirstPathForCategory: boolean;
  public displayInspectionDataOnTopOfProtocolPdf: boolean;
  public defectTableParams: DefectTableParams;
  public defectsMap: Map<string, Map<string, ProtocolPdfResultDto[]>>;
}
